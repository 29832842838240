import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  MenuItem,
  Typography,
  colors,
} from '@mui/material';
import { Stack } from '@mui/system';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { OfferRemarketingAction } from '../../../../../api/util/RemarketingAction.js';
import {
  FixedPriceRemarketingVehicle,
  RemarketingActionType,
  RemarketingVehicleStatus,
} from '../../../../../api/util/RemarketingVehicle.js';
import SectionItem from '../../../../common-ui/components/SectionItem.js';
import { EmptyState, TIMEOUT } from '../../../../common-ui/index.js';
import { useBrand } from '../../../../hooks/useBrand.js';
import { sx } from '../../../../util/sx.js';
import { useListAdminOffers } from '../../../hooks/queries/useListAdminOffers.js';
import { useRemarketingEditor } from '../../../hooks/useRemarketingEditor.js';
import { RemarketingVehicleType, Section } from '../types.js';
import AcceptRejectOfferModal from './Details/AcceptRejectOfferModal.js';
import { OfferItem } from './Details/OfferHistoryItem.js';

const styles = sx({
  divider: {
    height: 2,
  },
  lost: {
    textDecoration: 'line-through',
  },
  highlight: {
    '@keyframes blink': {
      '0%': {
        bgcolor: colors.yellow[50],
      },
      '50%': {
        bgcolor: colors.yellow[400],
      },
      '100%': {
        bgcolor: colors.yellow[50],
      },
    },

    '&.highlight-enter *': {
      animation: 'blink 1s infinite',
      fontWeight: 'fontWeightBold',
    },
  },
});

type AdminOffersHistoryProps = Omit<Section, 'vehicle'> & {
  remarketingVehicle: FixedPriceRemarketingVehicle;
  onUpdate: (remarketingVehicle: RemarketingVehicleType) => void;
};

const AdminOffersHistory: FunctionComponent<AdminOffersHistoryProps> = ({
  title,
  remarketingVehicle,
  sectionItemsProps,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const { currentBrand } = useBrand();
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useListAdminOffers(
      currentBrand
        ? {
            brand: currentBrand,
            remarketingVehicleId: remarketingVehicle.id,
            actionType: RemarketingActionType.OFFER,
            order: 'desc',
          }
        : undefined,
    );

  const [selectedAction, setSelectedAction] = useState<{
    action: OfferRemarketingAction;
    accept: boolean;
  } | null>(null);

  const openModal = (action: OfferRemarketingAction, accept: boolean) => {
    setSelectedAction({ action, accept });
  };

  const closeModal = () => setSelectedAction(null);

  const currency = remarketingVehicle.currency;

  const loadMore = () => {
    if (!currentBrand || hasNextPage || isFetchingNextPage) {
      return;
    }
    void fetchNextPage();
  };

  const offers = data?.pages.flatMap((p) => p.items);

  const empty =
    data?.pages[0]?.items?.length === 0 && !remarketingVehicle.currentOffer;

  const isEditor = useRemarketingEditor();

  return (
    <SectionItem title={title} {...sectionItemsProps}>
      {empty ? (
        <EmptyState>
          {t(`pages.remarketingVehiclePage.fixedPrice.noOffersHistory`)}
        </EmptyState>
      ) : (
        <Stack spacing={2}>
          <Stack
            divider={<Divider color="#F7F8F9" sx={styles.divider} />}
            spacing={1}
          >
            <Grid container>
              <Grid item xs>
                <Typography fontWeight="fontWeightBold" variant="body2">
                  {t(
                    'pages.remarketingVehiclePage.auction.biddingHistory.dateTime',
                  )}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography fontWeight="fontWeightBold" variant="body2">
                  {t('pages.remarketingVehiclePage.fixedPrice.offer')}
                </Typography>
              </Grid>
              <Grid item xs></Grid>
              <Grid item xs>
                <Typography fontWeight="fontWeightBold" variant="body2">
                  {t('pages.remarketingVehiclePage.fixedPrice.offerer')}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography fontWeight="fontWeightBold" variant="body2">
                  {t(
                    'pages.remarketingVehiclePage.auction.biddingHistory.dealer',
                  )}
                </Typography>
              </Grid>
              <Grid item xs></Grid>
            </Grid>

            {remarketingVehicle.currentOffer &&
              remarketingVehicle.currentOffer.type ===
                RemarketingActionType.BUY_NOW && (
                <Collapse>
                  <OfferItem
                    currency={currency}
                    highlight
                    item={remarketingVehicle.currentOffer}
                  />
                </Collapse>
              )}
            {offers?.map((item, idx) => {
              const showActions =
                isEditor &&
                remarketingVehicle.status === RemarketingVehicleStatus.ACTIVE &&
                !(item as OfferRemarketingAction).status;
              return (
                <SwitchTransition key={idx} mode="out-in">
                  <CSSTransition
                    appear={false}
                    classNames="highlight"
                    enter={idx === 0}
                    exit={false}
                    in={false}
                    key={item.id}
                    timeout={TIMEOUT}
                  >
                    <Box sx={styles.highlight}>
                      <OfferItem
                        actions={
                          showActions && (
                            <Stack direction="row" spacing={2}>
                              <Button
                                onClick={() =>
                                  openModal(
                                    item as OfferRemarketingAction,
                                    true,
                                  )
                                }
                                variant="outlined"
                              >
                                {t('accept')}
                              </Button>
                              <Button
                                onClick={() =>
                                  openModal(
                                    item as OfferRemarketingAction,
                                    false,
                                  )
                                }
                                variant="outlined"
                              >
                                {t('reject')}
                              </Button>
                            </Stack>
                          )
                        }
                        currency={currency}
                        highlight={
                          remarketingVehicle.currentOffer?.actionId === item.id
                        }
                        item={item as OfferRemarketingAction} // safe cast as we are querying by action type
                        key={item.id}
                        menuItems={
                          showActions && (
                            <div>
                              <MenuItem
                                onClick={() =>
                                  openModal(
                                    item as OfferRemarketingAction,
                                    true,
                                  )
                                }
                              >
                                {t('accept')}
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  openModal(
                                    item as OfferRemarketingAction,
                                    false,
                                  )
                                }
                              >
                                {t('reject')}
                              </MenuItem>
                            </div>
                          )
                        }
                      />
                    </Box>
                  </CSSTransition>
                </SwitchTransition>
              );
            })}
          </Stack>
          {hasNextPage && (
            <div>
              <LoadingButton
                loading={isFetchingNextPage}
                onClick={loadMore}
                variant="contained"
              >
                {t('loadMore')}
              </LoadingButton>
            </div>
          )}
        </Stack>
      )}
      {currentBrand && selectedAction && (
        <AcceptRejectOfferModal
          acceptOffer={selectedAction.accept}
          actionId={selectedAction.action.id}
          brand={currentBrand}
          isLoading={isLoading || isFetchingNextPage}
          onClose={closeModal}
          onSubmit={() => {
            onUpdate({
              ...remarketingVehicle,
              status: selectedAction.accept
                ? RemarketingVehicleStatus.CLOSED // Optimistic update
                : remarketingVehicle.status,
            });
          }}
          open
          remarketingVehicle={remarketingVehicle}
        />
      )}
    </SectionItem>
  );
};

export default AdminOffersHistory;
