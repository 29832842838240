import { Decoder, PropDecoders } from '@fmtk/decoders';
import React, { useCallback } from 'react';
import { FormHook } from '../../hooks/index.js';
import {
  FormOptions,
  MakeForm,
  ProvideForm,
  useForm,
} from '../context/FormContext.js';

export interface FormBaseProps extends FormOptions {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  submitOnEnter?: boolean;
}

export type FormProps<T, Result> = FormBaseProps &
  (
    | {
        crossValidation?: Decoder<T>;
        fields: PropDecoders<T>;
        onSubmit: (value: T) => PromiseLike<Result> | Result;
      }
    | {
        form?: FormHook<T, Result>;
      }
  );

export function Form<T, Result>(props: FormProps<T, Result>): JSX.Element {
  if ('form' in props && props.form) {
    const { form, translations, ...rest } = props;
    return (
      <ProvideForm form={form} translations={translations}>
        <FormBase {...rest} />
      </ProvideForm>
    );
  } else if ('fields' in props && props.fields) {
    const { crossValidation, translations, fields, onSubmit, ...rest } = props;
    return (
      <MakeForm
        crossValidation={crossValidation}
        fields={fields}
        submit={onSubmit}
        translations={translations}
      >
        <FormBase {...rest} />
      </MakeForm>
    );
  } else {
    return <FormBase {...props} />;
  }
}

function FormBase({
  children,
  className,
  disabled,
  style,
  submitOnEnter,
}: FormBaseProps): JSX.Element {
  const [, formBind] = useForm();

  const submit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      !disabled && formBind.submit();
    },
    [disabled, formBind],
  );

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLFormElement>) => {
      if (submitOnEnter && e.key === 'Enter' && !disabled) {
        formBind.submit();
      }
    },
    [disabled, formBind, submitOnEnter],
  );

  return (
    <form
      style={style}
      className={className}
      onSubmit={submit}
      onKeyDown={handleKeyPress}
    >
      {children}
    </form>
  );
}
