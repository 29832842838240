import { chain, maybe, number, object, string } from '@fmtk/decoders';
import { Dealer, decodeDealerProps } from '../../util/Dealer.js';
import { isUrl } from '../../util/isUrl.js';

export type UpsertDealerRequest = Omit<Dealer, 'id'> & { id?: number };

export const decodeUpsertDealerRequest = object<UpsertDealerRequest>({
  ...decodeDealerProps,
  website: maybe(chain(string, isUrl)),
  id: maybe(number),
});
