import { TFunction } from 'i18next';
import { AvailableBrands } from '../../../../api/util/AvailableBrands.js';
import { PDFTypes } from '../../../util/BrandsConfig.js';
import {
  AstonCompactLandscapePdf,
  AstonCompactPortraitPdf,
  AstonMartinDetailPortraitPdf,
  AstonOptionsLandscapePdf,
  AstonOptionsPortraitPdf,
  CompactLandscapeProps,
  CompactPortraitPdfProps,
  DetailPortraitPdfProps,
  OptionsLandscapePdfProps,
  OptionsPortraitPdfProps,
} from './AstonMartin/index.js';

import {
  BentleyDetailPortraitPdf,
  BentleyDetailPortraitPdfProps,
  BentleyOptionsLandscapePdf,
  BentleyOptionsLandscapePdfProps,
} from './Bentley/index.js';
import {
  RollsRoyceDetailPortraitPdf,
  RollsRoyceDetailPortraitPdfProps,
} from './RollsRoyce/index.js';

export type Report<T> = Partial<Record<PDFTypes, React.FunctionComponent<T>>>;

export type ReportTypeBase = {
  t: TFunction;
};

export type ReportType =
  | CompactPortraitPdfProps
  | CompactLandscapeProps
  | OptionsPortraitPdfProps
  | OptionsLandscapePdfProps
  | DetailPortraitPdfProps
  | BentleyDetailPortraitPdfProps
  | BentleyOptionsLandscapePdfProps
  | RollsRoyceDetailPortraitPdfProps;

const mapPdfs: Partial<Record<AvailableBrands, Report<ReportType>>> = {
  [AvailableBrands.AstonMartin]: {
    [PDFTypes.CompactPortrait]: AstonCompactPortraitPdf,
    [PDFTypes.CompactLandscape]: AstonCompactLandscapePdf,
    [PDFTypes.OptionsPortrait]: AstonOptionsPortraitPdf,
    [PDFTypes.OptionsLandscape]: AstonOptionsLandscapePdf,
    [PDFTypes.DetailedPortrait]: AstonMartinDetailPortraitPdf,
  },
  [AvailableBrands.Bentley]: {
    [PDFTypes.OptionsLandscape]: BentleyOptionsLandscapePdf,
    [PDFTypes.DetailedPortrait]: BentleyDetailPortraitPdf,
  },
  [AvailableBrands.RollsRoyce]: {
    [PDFTypes.DetailedPortrait]: RollsRoyceDetailPortraitPdf,
    [PDFTypes.DetailedPortraitGallery]: RollsRoyceDetailPortraitPdf,
  },
};

export function makePdf<T extends ReportType>(
  brand: AvailableBrands,
  pdfType: PDFTypes,
  props: T,
): JSX.Element | null {
  const brandPdfs = mapPdfs[brand];
  if (!brandPdfs) {
    return null;
  }

  const Component = brandPdfs[pdfType];
  if (!Component) {
    return null;
  }
  return <Component {...props} />;
}
