import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { CreateVehicleListingRequest } from '../../../../api/VehicleListingService/createVehicleListing/CreateVehicleListingRequest.js';
import { VehicleListing } from '../../../../api/util/Vehicle.js';
import { useMessageStore } from '../../../common-ui/index.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import {
  SearchVehicleListingsData,
  makeSearchVehicleListingsQueryKey,
} from '../queries/useSearchVehicleListings.js';

export const useAddVehicleListing = (): UseMutationResult<
  VehicleListing | undefined,
  unknown,
  CreateVehicleListingRequest
> => {
  const api = useApiClient();
  const queryClient = useQueryClient();
  const { showMessage } = useMessageStore();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async (request) => {
      return await api.vehicleListing.createVehicleListing(request);
    },
    onSuccess: (added, request) => {
      queryClient.setQueriesData<SearchVehicleListingsData>(
        {
          queryKey: makeSearchVehicleListingsQueryKey(request.vehicle.brand),
        },
        (prev) => {
          if (!!added) {
            if (prev) {
              const [firstPage, ...pages] = prev.pages;

              return {
                ...prev,
                pages: [
                  {
                    items: [added, ...firstPage.items],
                    count: firstPage.count + 1,
                  },
                  ...pages,
                ],
              };
            } else {
              return {
                pageParams: [],
                pages: [
                  {
                    items: [added],
                    count: 1,
                  },
                ],
              };
            }
          }
        },
      );
      showMessage({
        text: t('pages.vehicleAddPage.listingCreatedSuccessfully'),
        severity: 'success',
        dismissible: true,
        duration: 1000 * 10,
      });
    },
    onError: () => {
      showMessage({
        severity: 'error',
        text: t('errorOccurredMessage'),
        dismissible: true,
      });
    },
  });
};
