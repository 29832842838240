/**
 * A list of model types.
 */
export enum DbModelType {
  BiddingHistory = 'BiddingHistory',
  InAppNotification = 'InAppNotification',
  InventoryReportProcess = 'InventoryReportProcess',
  Dealer = 'Dealer',
  RemarketingAction = 'RemarketingAction',
  RemarketingNotification = 'RemarketingNotification',
  RemarketingVehicle = 'RemarketingVehicle',
  UserDocument = 'UserDocument',
  UserSettings = 'UserSettings',
  Vehicle = 'Vehicle',
  VehicleListing = 'VehicleListing',
  VehicleListingView = 'VehicleListingView',
  VehicleListingLog = 'VehicleListingLog',
  VehicleMedia = 'VehicleMedia',
  VehicleListingMilestone = 'VehicleListingMilestone',
  VehicleListingAnalytics = 'VehicleListingAnalytics',
  WebHookMeta = 'WebHookMeta',
  ArchivedVehicleListingView = 'ArchivedVehicleListingView',
  ArchiveVehicleListingProcess = 'ArchiveVehicleListingProcess',
  ImageQcAssign = 'ImageQcAssign',
  LeadLog = 'LeadLog',
}

/**
 * Represents keys to be used as references to entities stored outside of DynamoDB (eg: PostgreSQL)
 */
export enum ModelKey {
  AnalyticsEventType = 'AnalyticsEventType',
  Brand = 'Brand',
  Dealer = 'Dealer',
  FeedId = 'FeedId',
  MediaType = 'MediaType',
  MilestoneType = 'MilestoneType',
  Position = 'Position',
  Status = 'Status',
  Timestamp = 'Timestamp',
  User = 'User',
  Vin = 'Vin',
  OneTimeCode = 'OneTimeCode',
}

/**
 * Represents a mapping of DbModelTypes to some other value.
 */
export type DbModelMapType<V> = { [K in DbModelType]: V };

/**
 * Type helper to help create a type map that has an unconstrained value type.
 */
export function makeDbModelTypeOpenMap<M extends DbModelMapType<any>>(
  value: M,
): M {
  return value;
}

/**
 * Type helper to help create a type map.
 */
export function makeDbModelTypeMap<V>(
  value: DbModelMapType<V>,
): DbModelMapType<V> {
  return value;
}
