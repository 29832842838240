import { MappingPropertyBase } from '@opensearch-project/opensearch/api/types';
import { RemarketingType } from '../../api/util/RemarketingVehicle.js';
import { DealerDbModel } from '../DynamoDbService/model/DealerModel.js';
import { RemarketingVehicleDbModel } from '../DynamoDbService/model/RemarketingVehicleModel.js';
import { VehicleListingViewDbModel } from '../DynamoDbService/model/VehicleListingViewModel.js';
import { DbModelTypeMap } from '../DynamoDbService/model/models.js';
import { DbModelType } from '../DynamoDbService/model/types.js';
import { getArchivedVehicleListingViewIndexMapping } from '../OpenSearchClient/Indexes/getArchivedVehicleListingViewIndexMapping.js';
import { getDealerIndexMapping } from '../OpenSearchClient/Indexes/getDealerIndexMapping.js';
import { getLeadLogMapping } from '../OpenSearchClient/Indexes/getLeadLogMapping.js';
import { getVehicleListingAnalyticsMapping } from '../OpenSearchClient/Indexes/getVehicleListingAnalyticsMapping.js';
import { getVehicleListingMilestonesIndexMapping } from '../OpenSearchClient/Indexes/getVehicleListingMilestonesIndexMapping.js';
import { getVehicleListingViewIndexMapping } from '../OpenSearchClient/Indexes/getVehicleListingViewIndexMapping.js';

export enum IndexName {
  ArchivedVehicleListings = 'archived-vehicle-listings',
  RemarketingVehicles = 'remarketing-vehicles',
  InAppNotifications = 'in-app-notifications',
  VehicleListings = 'vehicle-listings',
  VehicleListingMilestones = 'vehicle-listing-milestones',
  VehicleListingAnalytics = 'vehicle-listing-analytics',
  WebHookMeta = 'webhook-meta',
  Dealer = 'dealer',
  LeadLog = 'lead-log',
}
export interface SearchIndexDef<T, M = any> {
  key(value: T): string;
  map?(value: T): M;
  mappings?: MappingPropertyBase;
  name: IndexName;
}

export type DbModelSearchKeyMap = {
  [K in DbModelType]?: SearchIndexDef<DbModelTypeMap[K]>;
};

function id({ id }: { id: string }): string {
  return id;
}

/**
 * Defines whether or not a model is indexable, and how to compute the key.
 */
export const DbModelIndexes: DbModelSearchKeyMap = {
  [DbModelType.RemarketingVehicle]: {
    key: id,
    name: IndexName.RemarketingVehicles,
    map: (value: RemarketingVehicleDbModel) => {
      const { watchers, excludedWatchers, ...listing } = value;
      let bidders: number[] | undefined;
      if (
        value.type === RemarketingType.AUCTION ||
        value.type === RemarketingType.FIXED_PRICE
      ) {
        bidders = value.bidders ? [...value.bidders] : undefined;
      }
      return {
        ...listing,
        watchers: watchers ? [...watchers] : undefined,
        excludedWatchers: excludedWatchers ? [...excludedWatchers] : undefined,
        bidders,
      };
    },
  },
  [DbModelType.InAppNotification]: {
    key: id,
    name: IndexName.InAppNotifications,
  },
  [DbModelType.VehicleListingView]: {
    key: (value: VehicleListingViewDbModel) => {
      if (value.id) {
        return value.id;
      }

      if (value.vehicleMedia?.length) {
        const [{ vehicleListingId }] = value.vehicleMedia;
        return vehicleListingId;
      }

      throw new Error(
        `Invalid VehicleListingViewDbModel, could not find Id: ${JSON.stringify(
          value,
        )}`,
      );
    },
    name: IndexName.VehicleListings,
    mappings: getVehicleListingViewIndexMapping(),
  },
  [DbModelType.ArchivedVehicleListingView]: {
    key: id,
    name: IndexName.ArchivedVehicleListings,
    mappings: getArchivedVehicleListingViewIndexMapping(),
  },
  [DbModelType.VehicleListingMilestone]: {
    key: id,
    name: IndexName.VehicleListingMilestones,
    mappings: getVehicleListingMilestonesIndexMapping(),
  },
  [DbModelType.VehicleListingAnalytics]: {
    key: id,
    name: IndexName.VehicleListingAnalytics,
    mappings: getVehicleListingAnalyticsMapping(),
  },
  [DbModelType.WebHookMeta]: {
    key: id,
    name: IndexName.WebHookMeta,
  },
  [DbModelType.Dealer]: {
    key: (value: DealerDbModel) => String(value.id),
    name: IndexName.Dealer,
    mappings: getDealerIndexMapping(),
  },
  [DbModelType.LeadLog]: {
    key: id,
    name: IndexName.LeadLog,
    mappings: getLeadLogMapping(),
  },
};
