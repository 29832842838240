import { CompareArrows } from '@mui/icons-material';
import {
  Autocomplete,
  IconButton,
  Popover,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  generatePath,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Dealer } from '../../../api/util/Dealer.js';
import { getAvailableBrandFromId } from '../../../api/util/getAvailableBrandFromId.js';
import { EmptyState } from '../../common-ui/index.js';
import {
  DealerSelectionType,
  useApiClient,
  useAsyncState,
  useCurrentUser,
  useDealer,
} from '../../hooks/index.js';

const buttonElementId = 'dealer-button';
const DealerSelector = () => {
  const { currentDealer, updateCurrentDealer } = useDealer();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [availableDealers, setAvailableDealers] = useAsyncState<Dealer[]>();
  const { value: user } = useCurrentUser();
  const { brand } = useParams();
  const api = useApiClient();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const location = useLocation();

  const dealerRoute = matchPath(
    { path: '/:brand/dealers/:dealer/*', end: false },
    location.pathname,
  );

  const dealerRemarketingRoute = matchPath(
    { path: '/:brand/dealers/:dealer/remarketing/*', end: false },
    location.pathname,
  );

  useEffect(() => {
    if (!user || !brand) {
      return;
    }

    const currentBrand = getAvailableBrandFromId(Number(brand));

    if (!brand) {
      return;
    }

    let dealers = user.policy.Dealer as string[];
    const regions = user.policy.Region;

    if (!!dealerRemarketingRoute) {
      const remarketingDealers = user.policy.RemarketingDealer as string[];
      dealers = _.intersection(dealers, remarketingDealers);
    }

    if (dealers && Array.isArray(dealers)) {
      setAvailableDealers(async () => {
        const { data } = await api.dealer.listDealers({
          brand: currentBrand,
          dealerIds: dealers.map((str) => Number(str)),
          regionIds: (regions || []) as string[],
        });
        return data;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, brand, setAvailableDealers, api.dealer]);

  if (availableDealers.error) {
    console.error(availableDealers.error);
    return (
      <EmptyState>An error ocurred while trying to load dealers.</EmptyState>
    );
  }
  if (availableDealers.loading) {
    return <Skeleton animation="wave" sx={{ width: '30%' }} />;
  }
  if (availableDealers.value?.length === 0) {
    return <EmptyState>No dealers assigned.</EmptyState>;
  }

  return (
    <>
      <Popover
        PaperProps={{
          sx: {
            borderRadius: '4px',
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={buttonElementId}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {availableDealers.value && (
          <Autocomplete
            getOptionLabel={(option) => option.name}
            groupBy={(option) => option.region.name}
            id="dealer-selector"
            isOptionEqualToValue={(option, value) => option.name === value.name}
            onChange={(_, dealer: DealerSelectionType | null) => {
              if (dealer !== null) {
                updateCurrentDealer(dealer);

                if (dealerRoute) {
                  const newRoute = generatePath(dealerRoute.pattern.path, {
                    ...dealerRoute.params,
                    dealer: dealer.id,
                  });

                  navigate(newRoute);
                }

                setOpen(false);
              }
            }}
            options={
              availableDealers.value
                ? availableDealers.value.sort(
                    (a, b) => -b.region.name.localeCompare(a.region.name),
                  )
                : []
            }
            renderInput={(params) => (
              <TextField {...params} error={currentDealer === null} label="" />
            )}
            sx={{ width: 400 }}
            value={currentDealer}
          />
        )}
      </Popover>
      <Stack direction="row" spacing={2}>
        <Typography variant="h6">{currentDealer?.name}</Typography>
        {availableDealers.value && availableDealers.value.length > 1 && (
          <IconButton
            aria-describedby={buttonElementId}
            onClick={handleClick}
            sx={{
              padding: 0,
            }}
          >
            <CompareArrows />
          </IconButton>
        )}
      </Stack>
    </>
  );
};

export default DealerSelector;
