import {
  decodeDownloadCsvResponse,
  method,
  none,
  service,
  UserPermissions,
} from '../util/index.js';
import { decoderCountDealersByRegionRequest } from './countDealersByRegion/CountDealersByRegionRequest.js';
import { decoderCountDealersByRegionResponse } from './countDealersByRegion/CountDealersByRegionResponse.js';
import { decodeDeleteDealerRequest } from './deleteDealer/DeleteDealerRequest.js';
import { decodeGetDealerFeaturesResponse } from './getDealerFeatures/GetDealerFeaturesResponse.js';
import { decodeListDealersRequest } from './listDealers/ListDealersRequest.js';
import { decodeListDealersResponse } from './listDealers/ListDealersResponse.js';
import { decodeUpsertDealerRequest } from './upsertDealer/UpsertDealerRequest.js';

export const DealerServiceDef = service({
  listDealers: method(decodeListDealersRequest, decodeListDealersResponse, [
    UserPermissions.Basic,
  ]),
  downloadDealersCsv: method(
    decodeListDealersRequest,
    decodeDownloadCsvResponse,
    [UserPermissions.Basic],
  ),
  countDealersByRegion: method(
    decoderCountDealersByRegionRequest,
    decoderCountDealersByRegionResponse,
    [UserPermissions.Basic],
  ),
  getDealerFeatures: method(none, decodeGetDealerFeaturesResponse, [
    UserPermissions.Support,
  ]),
  upsertDealer: method(decodeUpsertDealerRequest, none, [
    UserPermissions.Support,
  ]),
  deleteDealer: method(decodeDeleteDealerRequest, none, [
    UserPermissions.Support,
  ]),
});

export type DealerServiceDef = typeof DealerServiceDef;
