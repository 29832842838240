import { InputBaseComponentProps } from '@mui/material';
import { uniqueId } from 'lodash-es';
import { DateTime } from 'luxon';
import { FunctionComponent, useMemo } from 'react';
import { useConst } from '../../../hooks/useConst.js';
import { formatDate } from '../../../util/dates.js';
import { Cell, CellInput, useForm } from '../../index.js';

interface CellDateInputProps {
  label: string;
  name: string;
  readonly?: boolean;
  inputProps?: InputBaseComponentProps;
}

export const CellDateInput: FunctionComponent<CellDateInputProps> = ({
  name,
  label,
  readonly,
  inputProps,
}) => {
  const [{ values }, formBind] = useForm();
  const ref = useConst(() => uniqueId('id'));
  const formattedValue = useMemo(() => {
    const value = values[name];
    return value && DateTime.fromISO(value).isValid
      ? formatDate(DateTime.fromISO(value).toJSDate(), 'en-GB')
      : undefined;
  }, [values, name]);

  return (
    <Cell
      formattedValue={formattedValue}
      htmlFor={ref}
      label={label}
      name={name}
      readonly={readonly}
    >
      <CellInput
        id={ref}
        inputProps={inputProps}
        onChange={(e) => formBind.setValue(name, e.target.value)}
        type="date"
        value={values[name]}
      />
    </Cell>
  );
};

export default CellDateInput;
