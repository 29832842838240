import { enumValue, object } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/index.js';

export interface GetStockListByBrandRequest {
  brand: AvailableBrands;
}

export const decodeGetStockListByBrandRequest =
  object<GetStockListByBrandRequest>({
    brand: enumValue(AvailableBrands),
  });
