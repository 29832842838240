import { SxProps } from '@mui/system';
import { uniqueId } from 'lodash-es';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useConst } from '../../../hooks/useConst.js';
import { Cell, CellInput, useForm } from '../../index.js';

interface CellTextInputProps {
  label: string;
  name: string;
  readonly?: boolean;
  formattedValue?: string;
  maxLength?: number;
  cellStyleProps?: SxProps;
  required?: boolean;
  highlightChanges?: boolean;
}

export const CellTextInput: FunctionComponent<CellTextInputProps> = ({
  name,
  label,
  readonly,
  formattedValue,
  maxLength,
  cellStyleProps,
  required,
  highlightChanges,
}) => {
  const [{ values }, formBind, { translations }] = useForm();
  const ref = useConst(() => uniqueId('id'));
  const { t } = useTranslation();

  return (
    <Cell
      formattedValue={formattedValue}
      highlightChanges={highlightChanges}
      htmlFor={ref}
      label={
        translations
          ? t([`${translations}.labels.${name}`, label], {
              defaultValue: label,
            })
          : label
      }
      name={name}
      readonly={readonly}
      required={required}
      sx={cellStyleProps}
    >
      <CellInput
        id={ref}
        inputProps={{
          maxLength: maxLength,
        }}
        onChange={(e) => formBind.setValue(name, e.target.value)}
        tabIndex={1}
        value={values[name]}
      />
    </Cell>
  );
};

export default CellTextInput;
