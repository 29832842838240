import {
  array,
  boolean,
  chain,
  jsonDate as date,
  enumValue,
  jsonDate,
  maybe,
  number,
  object,
  string,
} from '@fmtk/decoders';
import { StockList } from '../../../core/index.js';
import { decodeCurrency } from '../../util/Currency.js';
import { decodeDealer } from '../../util/Dealer.js';
import {
  ListingStatus,
  ListingVisibility,
  decodeAnalyticsEvent,
  decodeListingExport,
  decodeListingImport,
  decodeVehicle,
  decodeVehicleOdometer,
  decodeVehiclePrice,
} from '../../util/Vehicle.js';
import { decodeVehicleMedia } from '../../util/VehicleMedia.js';
import { ListingLiveStatus, SyndicationType } from '../../util/index.js';
import { isUrl } from '../../util/isUrl.js';

export type GetStockListByBrandResponse = StockList[];

export const decodeStockList = object<StockList>({
  id: string,
  analyticsEvent: maybe(decodeAnalyticsEvent),
  createdByUserPrincipalId: maybe(string),
  createdDate: maybe(date),
  currency: maybe(decodeCurrency),
  damageStatus: maybe(string),
  dealer: decodeDealer,
  externalListingId: maybe(string),
  internalComments: maybe(string),
  lastUpdatedByUserPrincipalId: maybe(string),
  lastUpdatedDate: maybe(date),
  stockDate: date,
  listingExport: maybe(array(decodeListingExport)),
  listingImport: maybe(decodeListingImport(jsonDate)),
  liveStatus: array(enumValue(ListingLiveStatus)),
  isLive: boolean,
  odometer: maybe(decodeVehicleOdometer),
  price: maybe(decodeVehiclePrice),
  publicComments: maybe(string),
  listingStatus: enumValue(ListingStatus),
  locatorUrl: maybe(chain(string, isUrl)),
  previousOwners: maybe(number),
  registrationPlate: maybe(string),
  syndicationType: maybe(enumValue(SyndicationType)),
  urlVehicleDetailPage: maybe(string),
  vehicle: decodeVehicle(jsonDate),
  vehicleMedia: maybe(array(decodeVehicleMedia(jsonDate))),
  visibility: maybe(enumValue(ListingVisibility)),
  vinMd5Hash: maybe(string),
  youTubeLink: maybe(chain(string, isUrl)),
  daysInStock: number,
  relevantImage: maybe(string),
  approvedImages: maybe(number),
});

export const decodeGetStockListByBrandResponse = array(decodeStockList);
