import LoadingButton from '@mui/lab/LoadingButton/LoadingButton.js';
import {
  Button,
  ButtonProps,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Stack,
} from '@mui/material';
import React from 'react';

export type ButtonColor = Pick<ButtonProps, 'color'>;

export type ConfirmModalProps = DialogProps & {
  busy?: boolean;
  cancelText?: string;
  confirmText?: string;
  loadingIndicator?: React.ReactNode;
  onCancel?: () => void;
  onClose: () => void;
  onConfirm: () => void | Promise<void>;
} & { confirmColor?: ButtonColor['color'] };

export const ConfirmModal = ({
  busy,
  cancelText,
  children,
  confirmText,
  onCancel,
  onClose,
  onConfirm,
  open,
  title,
  loadingIndicator,
  confirmColor,
  ...props
}: ConfirmModalProps): JSX.Element => {
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={open} {...props}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <DialogContentText component="div">{children}</DialogContentText>
          <Stack
            direction={{
              xs: 'column',
              sm: 'row-reverse',
            }}
            spacing={2}
          >
            <LoadingButton
              color={confirmColor}
              fullWidth
              loading={busy}
              loadingIndicator={loadingIndicator || confirmText}
              onClick={onConfirm}
              variant="contained"
            >
              {confirmText || 'Confirm'}
            </LoadingButton>
            <Button
              color="inherit"
              fullWidth
              onClick={handleCancel}
              variant="outlined"
            >
              {cancelText || 'Cancel'}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
