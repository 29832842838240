/* eslint-disable react/jsx-sort-props */
import { maybe, ok, text } from '@fmtk/decoders';
import { Save } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton.js';
import { Box, Button, Stack, Typography } from '@mui/material';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdateUndefinedFields } from '../../../../api/VehicleListingService/updateVehicleListing/UpdateVehicleListingRequest.js';
import { VehicleListing } from '../../../../api/util/Vehicle.js';
import FormErrorsSummary from '../../../common-ui/components/FormErrorsSummary.js';
import LightTooltip from '../../../common-ui/components/LightTooltip.js';
import TableOfContents, {
  ContentItem,
} from '../../../common-ui/components/TableOfContents.js';
import {
  ConfirmModal,
  Form,
  useMessageStore,
} from '../../../common-ui/index.js';
import { useCreateForm } from '../../../hooks/useCreateForm.js';
import { useUpdateVehicleListing } from '../../hooks/mutations/useUpdateVehicleListing.js';
import { MAIN_CONTAINER_ID } from '../../layout/MainLayout.js';
import Section from '../Section.js';
import Comments from './Sections/Comments.js';
interface ListingCommentsProps {
  vehicleListing: VehicleListing;
  readonly?: boolean;
  onSave?: (vehicleListing: VehicleListing) => void;
}

const ListingComments: FunctionComponent<ListingCommentsProps> = ({
  vehicleListing,
  readonly,
  onSave,
}): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showMessage } = useMessageStore();
  const [resetConfirm, setResetConfirm] = useState(false);
  const { brand, dealer } = useParams();
  const { mutateAsync: updateVehicleListing } = useUpdateVehicleListing({
    onSettled: (data, error) => {
      if (!data || !!error) {
        return;
      }
      onSave && onSave(data);
    },
  });

  const contentItems: ContentItem[] = [
    {
      text: t('pages.vehiclePage.comments.publicComments'),
      hash: 'publicComments',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.comments.internalComments'),
      hash: 'internalComments',
      sectionElementRef: useRef(null),
    },
  ];

  const form = useCreateForm(
    {
      internalComments: maybe(text),
      publicComments: maybe(text),
    },
    async (values) => {
      if (!vehicleListing.id) {
        return;
      }

      await updateVehicleListing({
        id: vehicleListing.id,
        brand: vehicleListing.vehicle.brand,
        internalComments: values.internalComments,
        publicComments: values.publicComments,
        options: {
          updateUndefinedFields: [
            UpdateUndefinedFields.comments,
            UpdateUndefinedFields.publicComments,
          ],
        },
      });
    },
    (values) => {
      return ok(values);
    },

    [vehicleListing],
  );

  const [formState, formBind] = form;

  const [hasErrors, isDirty] = useMemo(() => {
    const isDirty = Object.keys(formState.dirty).length !== 0;
    return [
      isDirty && Object.values(formState.errors).some((x) => !!x.length),
      isDirty,
    ];
  }, [formState]);

  const toggleResetConfirm = () => {
    setResetConfirm((state) => !state);
  };

  const resetForm = useCallback(() => {
    formBind.reset({
      internalComments: vehicleListing.internalComments || '',
      publicComments: vehicleListing.publicComments || '',
    });
  }, [formBind, vehicleListing]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  useEffect(() => {
    if (formState.submitError) {
      showMessage({
        severity: 'error',
        text: t('errorOccurredMessage'),
        dismissible: true,
      });
    }
  }, [formState.submitError, showMessage, t]);

  return (
    <Stack
      direction={{
        sm: 'column-reverse',
        md: 'row',
      }}
      spacing={2}
    >
      <Stack flex={1}>
        <Section title={t('comments')}>
          <Stack spacing={4}>
            <Form form={form} translations="pages.vehiclePage.details.form">
              {/* Comments */}
              <div
                id={contentItems[0].hash}
                ref={
                  contentItems[0]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <Comments
                  title={t('pages.vehiclePage.comments.publicComments')}
                  readonly={readonly}
                  name="publicComments"
                  label="publicComments"
                  rows={15}
                />
              </div>
              <div
                id={contentItems[0].hash}
                ref={
                  contentItems[0]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <Comments
                  title={t('pages.vehiclePage.comments.internalComments')}
                  readonly={readonly}
                  name="internalComments"
                  label="internalComments"
                />
              </div>
              {!readonly && (
                <Box m={1} marginLeft="auto">
                  <Stack direction="row" justifyContent="end" spacing={1}>
                    <Button
                      disabled={!isDirty || formState.busy}
                      onClick={() => {
                        if (!isDirty) {
                          return;
                        }
                        toggleResetConfirm();
                      }}
                      variant="outlined"
                    >
                      {t('cancel')}
                    </Button>
                    <LightTooltip
                      title={hasErrors ? <FormErrorsSummary /> : ''}
                    >
                      <span>
                        <LoadingButton
                          disabled={hasErrors}
                          endIcon={<Save />}
                          loading={formState.busy}
                          loadingPosition="end"
                          type="submit"
                          variant="contained"
                        >
                          {t('save')}
                        </LoadingButton>
                      </span>
                    </LightTooltip>
                  </Stack>
                </Box>
              )}
            </Form>
          </Stack>
        </Section>
      </Stack>
      <div>
        <Section isSticky>
          <Stack>
            <TableOfContents
              items={contentItems}
              offsetPx={-80}
              scrollingElementSelector={MAIN_CONTAINER_ID}
            />
          </Stack>
        </Section>
      </div>
      <ConfirmModal
        onClose={toggleResetConfirm}
        onConfirm={() => {
          resetForm();
          toggleResetConfirm();
          navigate(`/${brand}/dealers/${dealer}/dashboard`);
        }}
        open={resetConfirm}
        title={t('attention')}
      >
        <Typography variant="body1">{t('discard')}</Typography>
      </ConfirmModal>
    </Stack>
  );
};

export default ListingComments;
