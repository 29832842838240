import { AvailableBrands } from '../../api/util/AvailableBrands.js';
import { SyndicationType } from '../../api/util/SyndicationType.js';
import { Option } from './Option.js';

export const mapBrandCertifiedDisplayName: Record<AvailableBrands, string> = {
  [AvailableBrands.AstonMartin]: 'Timeless',
  [AvailableBrands.Bentley]: 'Certified by Bentley',
  [AvailableBrands.Genesis]: 'Genesis Certified',
  [AvailableBrands.Kia]: 'Certified Used',
  [AvailableBrands.Lamborghini]: 'Selezione',
  [AvailableBrands.RollsRoyce]: 'Provenance',
};

export const syndicationTypeDisplayName = (
  brand: AvailableBrands,
): Record<SyndicationType, string> => ({
  [SyndicationType.Certified]: mapBrandCertifiedDisplayName[brand],
  [SyndicationType.Used]: 'Used (non-certified)',
  [SyndicationType.Demo]: 'Demo',
  [SyndicationType.New]: 'New',
});

export const syndicationTypeOptions = (brand: AvailableBrands): Option[] => {
  const displayOptions = syndicationTypeDisplayName(brand);
  return [
    {
      value: SyndicationType.Certified,
      text: displayOptions[SyndicationType.Certified],
    },
    {
      value: SyndicationType.Used,
      text: displayOptions[SyndicationType.Used],
    },
    {
      value: SyndicationType.Demo,
      text: displayOptions[SyndicationType.Demo],
    },
    {
      value: SyndicationType.New,
      text: displayOptions[SyndicationType.New],
    },
  ];
};
