/// <reference lib="dom" />
import AbortController from 'abort-controller';
import { HttpHeaders, HttpHeadersInit } from './HttpHeaders.js';
import { composeMiddleware, Middleware } from './Middleware.js';

export interface HttpClientRequest {
  body?: unknown;
  headers?: HttpHeadersInit;
  method?: string;
  timeout?: number;
  url: string;
}

export interface HttpClientResponse {
  body?: unknown;
  headers: HttpHeaders;
  status: number;
}

export interface HttpClient {
  (req: HttpClientRequest): PromiseLike<HttpClientResponse>;
}

export type HttpClientMiddleware = Middleware<HttpClient>;

export interface HttpClientOptions {
  requestTimeout?: number;
}

export type FetchLike = typeof fetch;

export function makeHttpClient(
  fetchImpl: FetchLike,
  middleware?: HttpClientMiddleware[],
  { requestTimeout = 20000 }: HttpClientOptions = {},
): HttpClient {
  async function baseClient(
    req: HttpClientRequest,
  ): Promise<HttpClientResponse> {
    if (
      req.body !== undefined &&
      req.body !== null &&
      typeof req.body !== 'string'
    ) {
      throw new Error(`unable to encode body: expected string`);
    }

    const controller = new AbortController();
    const timeout = req.timeout ?? requestTimeout;
    const timeoutHandle = setTimeout(() => controller.abort(), timeout);

    const result = await fetchImpl(req.url, {
      body: req.body ?? undefined,
      headers: HttpHeaders.wrap(req.headers)?.toRecordSingleValues(),
      method: req.method,
      signal: controller.signal as any,
    });

    clearTimeout(timeoutHandle);

    return {
      headers: HttpHeaders.fromWebApi(result.headers),
      status: result.status,
      body: await result.text(),
    };
  }

  if (!middleware?.length) {
    return baseClient;
  }
  return composeMiddleware(middleware)(baseClient);
}
