import i18next, { Resource } from 'i18next';
import { merge } from 'lodash-es';
import { Language } from '../util/BrandsConfig.js';
import csCZ from './cs-CZ/index.js';
import deAT from './de-AT/index.js';
import deDE from './de-DE/index.js';
import enGB from './en-GB/index.js';
import esES from './es-ES/index.js';
import frBE from './fr-BE/index.js';
import frFR from './fr-FR/index.js';
import itIT from './it-IT/index.js';
import jaJP from './ja-JP/index.js';
import koKR from './ko-KR/index.js';
import nlBE from './nl-BE/index.js';
import nlNL from './nl-NL/index.js';
import plPL from './pl-PL/index.js';
import ptPT from './pt-PT/index.js';
import ruRU from './ru-RU/index.js';
import skSK from './sk-SK/index.js';
import zhCN from './zh-CN/index.js';
import zhTW from './zh-TW/index.js';

export const languageMap = {
  en_gb: Language.en_gb,
  cs_cz: Language.cs_cz,
  de_at: Language.de_at,
  de_de: Language.de_de,
  es_es: Language.es_es,
  fr_be: Language.fr_be,
  fr_fr: Language.fr_fr,
  it_it: Language.it_it,
  ja_jp: Language.ja_jp,
  ko_kr: Language.ko_kr,
  nl_be: Language.nl_be,
  nl_nl: Language.nl_nl,
  pl_pl: Language.pl_pl,
  pt_pt: Language.pt_pt,
  ru_ru: Language.ru_ru,
  sk_sk: Language.sk_sk,
  zh_cn: Language.zh_cn,
  zh_tw: Language.zh_tw,
};

const resources = {
  'en-GB': enGB,
  'cs-CZ': csCZ,
  'de-AT': deAT,
  'de-DE': deDE,
  'es-ES': esES,
  'fr-BE': frBE,
  'fr-FR': frFR,
  'it-IT': itIT,
  'ja-JP': jaJP,
  'ko-KR': koKR,
  'nl-BE': nlBE,
  'nl-NL': nlNL,
  'pl-PL': plPL,
  'pt-PT': ptPT,
  'ru-RU': ruRU,
  'sk-SK': skSK,
  'zh-CN': zhCN,
  'zh-TW': zhTW,
};

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export async function initialiseTranslations(
  namespaces: string[],
  mergeResources: Resource[],
): Promise<void> {
  await i18next.init({
    returnNull: false,
    debug: false,
    fallbackNS: [...namespaces, 'common'],
    interpolation: {
      escapeValue: false, // React already does escaping
      skipOnVariables: false, // this allow continue using $t() fn into JSON file
    },
    lng: 'en-GB',
    fallbackLng: 'en-GB',
    resources: merge({}, resources, ...mergeResources),
  });
}

export async function changeLanguage(lng?: string): Promise<void> {
  await i18next.changeLanguage(standardizeLanguageCode(lng));
}

export function standardizeLanguageCode(lng?: string): string | undefined {
  return languageMap[lng?.toLocaleLowerCase() as keyof typeof languageMap];
}
