import {
  array,
  boolean,
  choose,
  enumValue,
  is,
  jsonDate,
  maybe,
  number,
  object,
  string,
  text,
} from '@fmtk/decoders';
import { decodeUserDocument, UserDocument } from './UserDocument.js';

import {
  decodeVehicle,
  decodeVehicleOdometer,
  Vehicle,
  VehicleOdometer,
} from './Vehicle.js';
import { decodeVehicleMedia, VehicleMedia } from './VehicleMedia.js';

export enum RemarketingActionType {
  BID = 'BID',
  OFFER = 'OFFER',
  COMMENT = 'COMMENT',
  CANCEL = 'CANCEL',
  ADD_TO_WATCHLIST = 'ADD_TO_WATCHLIST',
  DELETE_FROM_WATCHLIST = 'DELETE_FROM_WATCHLIST',
  RELIST = 'RELIST',
  ARCHIVE = 'ARCHIVE',
  BUY_NOW = 'BUY_NOW',
  TRANSFER = 'TRANSFER',
}

export enum RemarketingVehicleStatus {
  PIPELINE = 'PIPELINE',
  VISIBLE = 'VISIBLE',
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  TRANSFERRED = 'TRANSFERRED',
  CANCELLED = 'CANCELLED',
}

export enum RemarketingType {
  UNASSIGNED = 'UNASSIGNED',
  AUCTION = 'AUCTION',
  FIXED_PRICE = 'FIXED_PRICE',
}

export const RemarketingTypeDisplayName: Record<RemarketingType, string> = {
  AUCTION: 'Auction',
  FIXED_PRICE: 'Fixed price',
  UNASSIGNED: 'Unassigned',
};

export const editableStates = [
  RemarketingVehicleStatus.PIPELINE,
  RemarketingVehicleStatus.VISIBLE,
];

export interface RemarketingVehicleBase<V = Vehicle, M = VehicleMedia<Date>> {
  id: string;
  vehicle: V;
  countries: string[];
  status: RemarketingVehicleStatus;
  adminPrincipalId: string;
  vehicleMedia: M[];
  notes?: string;
  views?: number;
  shortVin?: string;
  registrationPlate?: string;
  currency?: string;
  archived?: boolean;
  lastStatusChange?: number;
  odometer?: VehicleOdometer;
  visibilityDate?: number;
}

export interface RemarketingUnassignedVehicle<
  V = Vehicle,
  M = VehicleMedia,
  W extends Iterable<string> = string[],
> extends RemarketingVehicleBase<V, M> {
  type: RemarketingType.UNASSIGNED;
  watchers?: W;
  excludedWatchers?: W;
}

export interface CurrentBid {
  currentPrice: number;
  maxBid: number;
  bidderUserName: string;
  bidderUserEmail: string;
  bidderUserId: string;
  actionId: string;
  timestamp: number;
  dealerId: number;
  dealerName: string;
}

export interface AuctionRemarketingVehicle<
  V = Vehicle,
  M = VehicleMedia,
  B extends Iterable<number> = number[],
  W extends Iterable<string> = string[],
> extends RemarketingVehicleBase<V, M> {
  startPrice: number;
  reservePrice: number;
  type: RemarketingType.AUCTION;
  currentBid?: CurrentBid;
  visibilityDate: number;
  startingDate: number;
  closingDate: number;
  bidders?: B;
  watchers?: W;
  excludedWatchers?: W;
  currency: string;
  termsAndConditions?: UserDocument;
  odometer: VehicleOdometer;
}

export interface CurrentOffer {
  value: number;
  userName: string;
  userEmail: string;
  userId: string;
  actionId: string;
  timestamp: number;
  dealerId: number;
  dealerName: string;
  type: RemarketingActionType.BUY_NOW | RemarketingActionType.OFFER;
}

export interface FixedPriceRemarketingVehicle<
  V = Vehicle,
  M = VehicleMedia,
  B extends Iterable<number> = number[],
  W extends Iterable<string> = string[],
> extends RemarketingVehicleBase<V, M> {
  price: number;
  visibilityDate: number;
  startingDate: number;
  closingDate?: number;
  bidders?: B;
  watchers?: W;
  excludedWatchers?: W;
  currency: string;
  type: RemarketingType.FIXED_PRICE;
  currentOffer?: CurrentOffer;
  acceptsOffers?: boolean;
  termsAndConditions?: UserDocument;
  odometer: VehicleOdometer;
}

export type RemarketingVehicle<
  V = Vehicle,
  M = VehicleMedia,
  B extends Iterable<number> = Array<number>,
  W extends Iterable<string> = string[],
> =
  | RemarketingUnassignedVehicle<V, M, W>
  | AuctionRemarketingVehicle<V, M, B, W>
  | FixedPriceRemarketingVehicle<V, M, B, W>;

export const remarketingVehicleBaseDecoders = {
  id: text,
  vehicle: decodeVehicle(jsonDate),
  countries: array(text),
  status: enumValue(RemarketingVehicleStatus),
  adminPrincipalId: text,
  vehicleMedia: array(decodeVehicleMedia(jsonDate)),
  notes: maybe(text),
  views: maybe(number),
  shortVin: maybe(text),
  registrationPlate: maybe(text),
  currency: maybe(text),
  archived: maybe(boolean),
  lastStatusChange: maybe(number),
  odometer: maybe(decodeVehicleOdometer),
  visibilityDate: maybe(number),
};

export const decodeRemarketingVehicleBase = object<RemarketingVehicleBase>(
  remarketingVehicleBaseDecoders,
);

export const decodeRemarketingUnassignedVehicle =
  object<RemarketingUnassignedVehicle>({
    ...remarketingVehicleBaseDecoders,
    type: is(RemarketingType.UNASSIGNED),
    watchers: maybe(array(string)),
    excludedWatchers: maybe(array(string)),
  });

export const decodeCurrentBid = object<CurrentBid>({
  bidderUserEmail: text,
  bidderUserName: text,
  bidderUserId: text,
  currentPrice: number,
  maxBid: number,
  actionId: text,
  timestamp: number,
  dealerId: number,
  dealerName: text,
});

export const decodeAuctionRemarketingVehicle =
  object<AuctionRemarketingVehicle>({
    ...remarketingVehicleBaseDecoders,
    startPrice: number,
    reservePrice: number,
    type: is(RemarketingType.AUCTION),
    currentBid: maybe(decodeCurrentBid),
    bidders: maybe(array(number)),
    watchers: maybe(array(string)),
    excludedWatchers: maybe(array(string)),
    visibilityDate: number,
    startingDate: number,
    closingDate: number,
    currency: text,
    termsAndConditions: maybe(decodeUserDocument),
    odometer: decodeVehicleOdometer,
  });

export const decodeCurrentOffer = object<CurrentOffer>({
  actionId: string,
  dealerId: number,
  dealerName: string,
  userId: string,
  timestamp: number,
  userEmail: string,
  userName: string,
  value: number,
  type: is(RemarketingActionType.OFFER, RemarketingActionType.BUY_NOW),
});

export const decodeFixedPriceRemarketingVehicle =
  object<FixedPriceRemarketingVehicle>({
    ...remarketingVehicleBaseDecoders,
    price: number,
    type: is(RemarketingType.FIXED_PRICE),
    bidders: maybe(array(number)),
    watchers: maybe(array(string)),
    excludedWatchers: maybe(array(string)),
    visibilityDate: number,
    startingDate: number,
    closingDate: maybe(number),
    currency: text,
    currentOffer: maybe(decodeCurrentOffer),
    acceptsOffers: maybe(boolean),
    termsAndConditions: maybe(decodeUserDocument),
    odometer: decodeVehicleOdometer,
  });

export const decodeRemarketingVehicle = choose(
  decodeRemarketingUnassignedVehicle,
  decodeAuctionRemarketingVehicle,
  decodeFixedPriceRemarketingVehicle,
);
