import { Decoder, DecoderError, Result } from '@fmtk/decoders';
import debug from 'debug';
import { Dispatch } from 'react';
import { nextState } from '../../util/index.js';

const trace = debug('controllers:FormController');

export type FormDirty<T> = { [K in keyof T]?: boolean };
export type FormErrors<T> = { [K in keyof T]?: DecoderError[] };
export type FormValuesInput<T> = { [K in keyof T]-?: unknown };
export type FormValuesOutput<T> = { [K in keyof T]-?: T[K] };

export interface FormState<T, Result> {
  busy: boolean;
  dirty: FormDirty<T>;
  errors: FormErrors<T>;
  result: Result | undefined;
  submitError: boolean;
  values: FormValuesOutput<T>;
}

export interface FormController<T> {
  reset(value?: FormValuesInput<T>): void;
  setErrors(errors: DecoderError[]): void;
  setValue(key: keyof T, value: unknown, clean?: boolean): void;
  submit(): void;
  validate(): Result<T>;
}

export interface FormControllerDeps<T, SubmitResult> {
  decoder: Decoder<T>;
  initialValue: FormValuesInput<T>;
  dispatch: Dispatch<FormState<T, SubmitResult>>;
  submit: (
    value: T,
    controller: FormController<T>,
  ) => PromiseLike<SubmitResult> | SubmitResult;
}

export function makeFormController<T, SubmitResult>({
  decoder,
  initialValue,
  dispatch,
  submit: doSubmit,
}: FormControllerDeps<T, SubmitResult>): FormController<T> {
  let allDirty = false;
  let busy = false;
  let currentValue: FormValuesInput<T> = initialValue;
  let currentResult = decoder(currentValue);
  let dirty: FormDirty<T> = {};
  let manualErrors: FormErrors<T> = {};
  let result: SubmitResult | undefined;
  let submitError = false;

  function doDispatch(): void {
    let errors = manualErrors;

    if (!currentResult.ok) {
      errors = { ...errors };

      for (const error of currentResult.error) {
        if (!error.field) {
          continue;
        }
        if (allDirty || dirty[error.field as keyof T]) {
          const fieldErrs = errors[error.field as keyof T];
          errors[error.field as keyof T] = fieldErrs
            ? fieldErrs.concat([error])
            : [error];
        }
      }
    }
    dispatch({
      busy,
      dirty,
      errors,
      result,
      submitError,
      values: currentValue as FormValuesOutput<T>,
    });
  }

  function reset(value?: FormValuesInput<T>): void {
    allDirty = false;
    currentValue = value ?? initialValue;
    currentResult = decoder(currentValue);
    manualErrors = {};
    dirty = {};
    doDispatch();
  }

  function setErrors(errors: DecoderError[]): void {
    manualErrors = Object.fromEntries(errors.map((x) => [x.field, x]));
    doDispatch();
  }

  function setValue(key: keyof T, value: unknown, clean?: boolean): void {
    trace(`setValue key=%s clean=%o value=%o`, key, !!clean, value);
    const nextValue = nextState(currentValue[key], value);

    currentValue = {
      ...currentValue,
      [key]: nextValue,
    };
    dirty = {
      ...dirty,
      [key]: !clean,
    };
    manualErrors = {
      ...manualErrors,
      [key]: [],
    };
    currentResult = decoder(currentValue);
    doDispatch();
  }

  function submit(): void {
    void (async () => {
      if (busy) {
        return;
      }
      const value = validate();
      if (!value.ok) {
        return;
      }
      try {
        busy = true;
        submitError = false;
        doDispatch();
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        result = await doSubmit(value.value, self);
        busy = false;
        doDispatch();
      } catch (err) {
        trace(`submit error %O`, err);
        busy = false;
        submitError = true;
        doDispatch();
      }
    })();
  }

  function validate(): Result<T> {
    allDirty = true;
    currentResult = decoder(currentValue);
    manualErrors = {};
    doDispatch();
    trace(`validate value=%o, result=%o`, currentValue, currentResult);
    return currentResult;
  }

  const self = {
    reset,
    setErrors,
    setValue,
    submit,
    validate,
  };
  return self;
}
