import {
  Decoder,
  ExtraFields,
  ObjectDecoder,
  PropDecoders,
  UndefinedFields,
  array,
  boolean,
  chain,
  choose,
  jsonDate as date,
  enumValue,
  maybe,
  number,
  object,
  record,
  string,
} from '@fmtk/decoders';
import { VehicleListingDbModel } from '../../core/DynamoDbService/model/VehicleListingModel.js';
import { VehicleListingViewDbModel } from '../../core/DynamoDbService/model/VehicleListingViewModel.js';
import { VehicleDbModel } from '../../core/DynamoDbService/model/VehicleModel.js';
import { AnalyticsEventType } from './AnalyticsEventType.js';
import { AvailableBrands } from './AvailableBrands.js';
import { Currency, decodeCurrency } from './Currency.js';
import {
  Dealer,
  DealerIdPartial,
  DealerSummary,
  decodeDealer,
  decodeDealerIdPartial,
  decodeDealerSummary,
} from './Dealer.js';
import { HandDrive } from './HandDrive.js';
import { ListingLiveStatus } from './ListingLiveStatus.js';
import { SyndicationType } from './SyndicationType.js';
import { VehicleMedia, decodeVehicleMedia } from './VehicleMedia.js';
import { isUrl } from './isUrl.js';

export enum ListingImportType {
  Manual = 'MANUAL',
  Feed = 'FEED',
}

export enum VehicleColourType {
  CarpetColour = 'carpetColour',
  ExteriorColour = 'exteriorColour',
  ExteriorMetaColour = 'exteriorMetaColour',
  HeadliningColour = 'headliningColour',
  InteriorColour = 'interiorColour',
  InteriorMetaColour = 'interiorMetaColour',
  SeatColour = 'seatColour',
  SecondaryExteriorColour = 'secondaryExteriorColour',
  SecondaryInteriorColour = 'secondaryInteriorColour',
  SteeringWheelColour = 'steeringWheelColour',
  Veneer = 'veneer',
}

export enum PriceType {
  StandInValue = 'standInValue',
  Retail = 'retail',
  Trade = 'trade',
  RetailPlusTax = 'retailPlusTax',
  LessTaxes = 'lessTaxes',
  NetPrice = 'netPrice',
  BasePrice = 'basePrice',
}

export enum ListingStatus {
  ForSale = 'For Sale',
  Sold = 'Sold',
  SoldSubjectToFinance = 'Sold Subject to Finance',
  UnderOffer = 'Under Offer',
  UnderConversion = 'Under Conversion',
}

export const mapListingStatusPrice: Partial<Record<ListingStatus, number>> = {
  [ListingStatus.Sold]: 999995,
  [ListingStatus.SoldSubjectToFinance]: 999994,
  [ListingStatus.UnderOffer]: 999993,
  [ListingStatus.UnderConversion]: 999992,
};

export enum FuelType {
  Diesel = 'DIESEL',
  Electric = 'ELECTRIC',
  Hybrid = 'HYBRID',
  Petrol = 'PETROL',
  PHEV = 'PHEV',
}

export const fuelTypeDisplayName: Record<FuelType, string> = {
  [FuelType.Diesel]: 'Diesel',
  [FuelType.Electric]: 'Electric',
  [FuelType.Hybrid]: 'Hybrid',
  [FuelType.Petrol]: 'Petrol',
  [FuelType.PHEV]: 'PHEV',
};

export const getFuelTypeDisplayName = (fuelType: FuelType): string =>
  fuelTypeDisplayName[fuelType];

export const mapFuelTypeIds: { [key: number]: FuelType } = {
  1: FuelType.Petrol,
  2: FuelType.Diesel,
  3: FuelType.Electric,
  4: FuelType.Hybrid,
  5: FuelType.PHEV,
};

export const getFuelTypeId = (fuelType: FuelType): number => {
  for (const key in mapFuelTypeIds) {
    if (mapFuelTypeIds[key] === fuelType) {
      return parseInt(key);
    }
  }
  throw Error('Not a valid fuel type');
};

export enum SyncStatus {
  Success = 'SUCCESS',
  Fail = 'FAIL',
}

export enum ListingVisibility {
  Visible = 'Visible',
  Hidden = 'Hidden',
}

export type VehicleFeatures = Record<string, string[] | undefined>;
export const decodeVehicleFeatures = record(string, maybe(array(string)));

export type WltpTest = {
  batteryRange?: number;
  batteryRangeCity?: number;
  co2ClassDischargedBattery?: string;
  co2ClassWeightedCombined?: string;
  co2Emissions?: number;
  comsumptionCombinedDischargedBattery?: number;
  consumptionCombined?: number;
  consumptionCombinedWeighted?: number;
  consumptionExtraHigh?: number;
  consumptionHigh?: number;
  consumptionLow?: number;
  consumptionMid?: number;
  powerConsumptionCombined?: number;
  powerConsumptionCombinedWeighted?: number;
  weightedCombined?: number;
};

export const decodeWltpTest = object<WltpTest>({
  batteryRange: maybe(number),
  batteryRangeCity: maybe(number),
  co2ClassDischargedBattery: maybe(string),
  co2ClassWeightedCombined: maybe(string),
  co2Emissions: maybe(number),
  comsumptionCombinedDischargedBattery: maybe(number),
  consumptionCombined: maybe(number),
  consumptionCombinedWeighted: maybe(number),
  consumptionExtraHigh: maybe(number),
  consumptionHigh: maybe(number),
  consumptionLow: maybe(number),
  consumptionMid: maybe(number),
  powerConsumptionCombined: maybe(number),
  powerConsumptionCombinedWeighted: maybe(number),
  weightedCombined: maybe(number),
});

export type NedcTest = {
  nedcUrbanFuelEconomy?: number;
  nedcExtraUrbanFuelEconomy?: number;
  nedcCombinedFuelEconomy?: number;
  nedcCo2Emissions?: number;
};

export const decodeNedcTest = object<NedcTest>({
  nedcUrbanFuelEconomy: maybe(number),
  nedcExtraUrbanFuelEconomy: maybe(number),
  nedcCombinedFuelEconomy: maybe(number),
  nedcCo2Emissions: maybe(number),
});

export type VehicleOdometer = {
  value?: number;
  units?: string;
  odometerOnApplication?: boolean;
};

export const decodeVehicleOdometer = object<VehicleOdometer>({
  value: maybe(number),
  units: maybe(string),
  odometerOnApplication: maybe(boolean),
});

export type VehicleEngine = {
  id?: number;
  bhp?: string;
  capacity?: number;
  cylinders?: string;
  description?: string;
  fuelType?: string;
  powerKw?: string;
  torqueLbft?: string;
  torqueNm?: string;
};

export const decodeVehicleEngine = object<VehicleEngine>({
  id: maybe(number),
  bhp: maybe(string),
  capacity: maybe(number),
  cylinders: maybe(string),
  description: maybe(string),
  fuelType: maybe(string),
  powerKw: maybe(string),
  torqueLbft: maybe(string),
  torqueNm: maybe(string),
});

export type ListingImport<DateType extends Date | number = Date> = {
  type: ListingImportType;
  feedId?: string;
  lastSyncDate?: DateType;
  raw?: string;
};

export const decodeListingImport = <D extends Date | number = Date>(
  dateDecoder: Decoder<D>,
): ObjectDecoder<ListingImport<D>> => {
  return object<ListingImport<D>>({
    type: enumValue(ListingImportType),
    feedId: maybe(string),
    lastSyncDate: maybe(dateDecoder),
    raw: maybe(string),
  });
};

export type ListingExport<DateType extends Date | number = Date> = {
  description?: string;
  lastSyncDate?: DateType;
  lastSyncStatus?: SyncStatus;
  raw?: string;
};

export const decodeListingExport = object<ListingExport>({
  description: maybe(string),
  lastSyncDate: maybe(date),
  lastSyncStatus: maybe(enumValue(SyncStatus)),
  raw: maybe(string),
});

export type VehicleAppearanceOptions = {
  carpetColour?: string;
  exteriorColour?: string;
  exteriorMetaColour?: string;
  headliningColour?: string;
  interiorColour?: string;
  interiorMetaColour?: string;
  seatColour?: string;
  secondaryExteriorColour?: string;
  secondaryInteriorColour?: string;
  steeringWheelColour?: string;
  veneer?: string;
};

export const decodeVehicleAppearanceOptions = object<VehicleAppearanceOptions>({
  carpetColour: maybe(string),
  exteriorColour: maybe(string),
  exteriorMetaColour: maybe(string),
  headliningColour: maybe(string),
  interiorColour: maybe(string),
  interiorMetaColour: maybe(string),
  seatColour: maybe(string),
  secondaryExteriorColour: maybe(string),
  secondaryInteriorColour: maybe(string),
  steeringWheelColour: maybe(string),
  veneer: maybe(string),
});

export type Transmission = {
  id: number;
  name?: string;
  metaName?: string;
};

export const decodeTransmission = object<Transmission>({
  id: number,
  name: maybe(string),
  metaName: maybe(string),
});

export type BodyStyle = {
  id?: number;
  name?: string;
  metaName?: string;
};

export const decodeBodyStyle = object<BodyStyle>({
  id: maybe(number),
  name: maybe(string),
  metaName: maybe(string),
});

export type BrandSpecific = {
  [AvailableBrands.RollsRoyce]?: {
    collection?: string;
  };
  [AvailableBrands.AstonMartin]?: {
    bodystyleDescription?: string;
    isHeritage?: boolean;
    isQ?: boolean;
    isSpecial?: boolean;
  };
  [AvailableBrands.Lamborghini]?: {
    lfsModelCode?: string;
  };
  [AvailableBrands.Kia]?: {
    engine?: string;
  };
};

export const decodeBrandSpecific = record(
  enumValue(AvailableBrands),
  record(string, maybe(choose(string, boolean))),
);

export type VehicleModel = {
  id: number;
  name?: string;
  code?: string;
  groupName?: string;
  capCode?: string;
  capId?: number;
};

export const decodeVehicleModel = object<VehicleModel>({
  id: number,
  name: maybe(string),
  code: maybe(string),
  groupName: maybe(string),
  capCode: maybe(string),
  capId: maybe(number),
});

export type ListingChangeLog<DateType extends Date | number = Date> = {
  id?: number;
  date: DateType;
  userPrincipalId: string;
  changes: Record<string, string>;
};

export type VehicleRrp = {
  value?: number;
  currency?: string;
};

export const decodeVehicleRrp = object<VehicleRrp>({
  value: maybe(number),
  currency: maybe(string),
});

export const decodeListingChangeLog = object<ListingChangeLog>({
  id: maybe(number),
  date: date,
  userPrincipalId: string,
  changes: record(string, string),
});

export type Vehicle<DateType extends Date | number = Date> = {
  appearanceOptions?: VehicleAppearanceOptions;
  bodyStyle?: BodyStyle;
  brandSpecific?: BrandSpecific;
  brand: AvailableBrands;
  consumptionEmissionsDisclaimer?: string;
  damageStatus?: string;
  engine?: VehicleEngine;
  environmentalLabel?: string;
  euroEmissionsClass?: string;
  externalListingId?: string;
  features?: VehicleFeatures;
  handDrive?: HandDrive;
  model: VehicleModel;
  modelYear?: number;
  nedcTest?: NedcTest;
  previousOwners?: number;
  productionDate?: DateType;
  registrationDate?: DateType;
  registrationYear?: number;
  registrationMonth?: string;
  rrp?: VehicleRrp;
  techSpecs?: {
    maxSpeedMph?: string;
    maxSpeedKph?: string;
    kerbWeight?: string;
    length?: string;
    width?: string;
    acceleration0100Kph?: string;
    acceleration060Mph?: string;
  };
  transmission?: Transmission;
  variant?: string;
  vin: string;
  wltpTest?: WltpTest;
  warrantyExpiryDate?: DateType;
};

export const decodeVehicleProps = <D extends Date | number = Date>(
  dateDecoder: Decoder<D>,
): PropDecoders<Vehicle<D>> => {
  return {
    brand: enumValue(AvailableBrands),
    brandSpecific: maybe(decodeBrandSpecific),
    variant: maybe(string),
    model: decodeVehicleModel,
    handDrive: maybe(enumValue(HandDrive)),
    bodyStyle: maybe(decodeBodyStyle),
    modelYear: maybe(number),
    productionDate: maybe(dateDecoder),
    registrationDate: maybe(dateDecoder),
    registrationYear: maybe(number),
    registrationMonth: maybe(string),
    rrp: maybe(decodeVehicleRrp),
    transmission: maybe(decodeTransmission),
    vin: string,
    engine: maybe(decodeVehicleEngine),
    wltpTest: maybe(decodeWltpTest),
    nedcTest: maybe(decodeNedcTest),
    environmentalLabel: maybe(string),
    euroEmissionsClass: maybe(string),
    techSpecs: maybe(record(string, maybe(string))),
    appearanceOptions: maybe(decodeVehicleAppearanceOptions),
    features: maybe(decodeVehicleFeatures),
    warrantyExpiryDate: maybe(dateDecoder),
    consumptionEmissionsDisclaimer: maybe(string),
    damageStatus: maybe(string),
    externalListingId: maybe(string),
    previousOwners: maybe(number),
  };
};

export const decodeVehicle = <D extends Date | number = Date>(
  dateDecoder: Decoder<D>,
): ObjectDecoder<Vehicle<D>> => {
  return object<Vehicle<D>>({
    ...decodeVehicleProps(dateDecoder),
  });
};

export type VehiclePartialId = Pick<Vehicle, 'brand' | 'vin'>;

export const decodeVehiclePartialId = object<VehiclePartialId>({
  brand: enumValue(AvailableBrands),
  vin: string,
});

export type VehiclePrice = {
  [PriceType.LessTaxes]?: number;
  [PriceType.NetPrice]?: number;
  [PriceType.Retail]?: number;
  [PriceType.RetailPlusTax]?: number;
  [PriceType.StandInValue]?: number;
  [PriceType.Trade]?: number;
  [PriceType.BasePrice]?: number;
  priceOnApplication?: boolean;
  german25aTaxSalesDisclaimer?: boolean;
  vatQualifying?: boolean;
  priceExcludesVat?: boolean;
};

export const decodeVehiclePrice = object<VehiclePrice>({
  [PriceType.LessTaxes]: maybe(number),
  [PriceType.NetPrice]: maybe(number),
  [PriceType.Retail]: maybe(number),
  [PriceType.RetailPlusTax]: maybe(number),
  [PriceType.StandInValue]: maybe(number),
  [PriceType.Trade]: maybe(number),
  [PriceType.BasePrice]: maybe(number),
  priceOnApplication: maybe(boolean),
  german25aTaxSalesDisclaimer: maybe(boolean),
  vatQualifying: maybe(boolean),
  priceExcludesVat: maybe(boolean),
});

export const decodeAnalyticsEvent = record(
  enumValue(AnalyticsEventType),
  maybe(number),
);

export type VehicleListing<DateType extends number | Date = Date> = {
  id: string;
  analyticsEvent?: Partial<Record<AnalyticsEventType, number | undefined>>;
  createdByUserPrincipalId?: string;
  createdDate?: DateType;
  currency?: Currency;
  damageStatus?: string;
  dealer: Dealer;
  externalListingId?: string;
  internalComments?: string;
  lastUpdatedByUserPrincipalId?: string;
  lastUpdatedDate?: DateType;
  stockDate: DateType;
  listingExport?: ListingExport[];
  listingImport?: ListingImport<DateType>;
  liveStatus: ListingLiveStatus[];
  isLive: boolean;
  odometer?: VehicleOdometer;
  previousOwners?: number;
  price?: VehiclePrice;
  publicComments?: string;
  registrationPlate?: string;
  listingStatus: ListingStatus;
  locatorUrl?: string;
  syndicationType?: SyndicationType;
  urlVehicleDetailPage?: string;
  vehicle: Vehicle<DateType>;
  vehicleMedia?: VehicleMedia<DateType>[];
  visibility?: ListingVisibility;
  vinMd5Hash?: string;
  youTubeLink?: string;
};

export const decodeVehicleListingProps = <D extends Date | number = Date>(
  dateDecoder: Decoder<D>,
): PropDecoders<VehicleListing<D>> => {
  return {
    id: string,
    analyticsEvent: maybe(decodeAnalyticsEvent),
    createdByUserPrincipalId: maybe(string),
    createdDate: maybe(dateDecoder),
    currency: maybe(decodeCurrency),
    damageStatus: maybe(string),
    dealer: decodeDealer,
    externalListingId: maybe(string),
    internalComments: maybe(string),
    lastUpdatedByUserPrincipalId: maybe(string),
    lastUpdatedDate: maybe(dateDecoder),
    previousOwners: maybe(number),
    stockDate: dateDecoder,
    listingExport: maybe(array(decodeListingExport)),
    listingImport: maybe(decodeListingImport(dateDecoder)),
    liveStatus: array(enumValue(ListingLiveStatus)),
    isLive: boolean,
    odometer: maybe(decodeVehicleOdometer),
    price: maybe(decodeVehiclePrice),
    publicComments: maybe(string),
    listingStatus: enumValue(ListingStatus),
    locatorUrl: maybe(chain(string, isUrl)),
    registrationPlate: maybe(string),
    syndicationType: maybe(enumValue(SyndicationType)),
    urlVehicleDetailPage: maybe(string),
    vehicle: decodeVehicle(dateDecoder),
    vehicleMedia: maybe(array(decodeVehicleMedia(dateDecoder))),
    visibility: maybe(enumValue(ListingVisibility)),
    vinMd5Hash: maybe(string),
    youTubeLink: maybe(chain(string, isUrl)),
  };
};

export const decodeVehicleListing = <D extends Date | number = Date>(
  dateDecoder: Decoder<D>,
): ObjectDecoder<VehicleListing<D>> => {
  return object<VehicleListing<D>>({
    ...decodeVehicleListingProps(dateDecoder),
  });
};

/**
 * It is a subset of the `VehicleListing` type. Used to update or create a new vehicle listing.
 * Omitting certain properties from the `VehicleListing` type which are not relevant at that time.
 */
export type VehicleListingForm<D extends Date | number = Date> = Omit<
  VehicleListing<D>,
  | 'liveStatus'
  | 'isLive'
  | 'analyticsEvent'
  | 'urlVehicleDetailPage'
  | 'id'
  | 'dealer'
> & { id?: string; dealer: DealerIdPartial };

export const decodeVehicleListingFormProps = <D extends Date | number = Date>(
  dateDecoder: Decoder<D>,
): PropDecoders<VehicleListingForm<D>> => {
  const {
    liveStatus,
    isLive,
    analyticsEvent,
    urlVehicleDetailPage,
    id,
    dealer,
    ...props
  } = decodeVehicleListingProps(dateDecoder);
  return {
    ...props,
    id: maybe(string),
    dealer: decodeDealerIdPartial,
  };
};

export const decodeVehicleListingForm = <D extends Date | number = Date>(
  dateDecoder: Decoder<D>,
): ObjectDecoder<VehicleListingForm<D>> => {
  return object<VehicleListingForm<D>>(
    decodeVehicleListingFormProps(dateDecoder),
  );
};

export interface AttachedMedia {
  mediaUrl: string;
  position: number;
}

export const decodeAttachedMedia = object({
  mediaUrl: string,
  position: number,
});

export type VehicleListingFormWithAttachedMedia<
  D extends Date | number = Date,
> = VehicleListingForm<D> & {
  attachedMedia?: AttachedMedia[];
};

export const decodeVehicleListingFormWithAttachedMedia = <
  D extends Date | number = Date,
>(
  dateDecoder: Decoder<D>,
): ObjectDecoder<VehicleListingFormWithAttachedMedia<D>> => {
  return object<VehicleListingFormWithAttachedMedia<D>>({
    ...decodeVehicleListingFormProps(dateDecoder),
    attachedMedia: maybe(array(decodeAttachedMedia)),
  });
};

export type VehicleListingSummary = Pick<
  VehicleListingDbModel,
  | 'id'
  | 'dealer'
  | 'vehicle'
  | 'currency'
  | 'price'
  | 'odometer'
  | 'isLive'
  | 'liveStatus'
  | 'stockDate'
  | 'syndicationType'
  | 'lastUpdatedDate'
>;

export const decodeVehicleListingSummary = object<VehicleListingSummary>(
  {
    id: string,
    dealer: decodeDealerIdPartial,
    vehicle: decodeVehiclePartialId,
    currency: decodeCurrency,
    price: decodeVehiclePrice,
    odometer: decodeVehicleOdometer,
    isLive: boolean,
    liveStatus: array(enumValue(ListingLiveStatus)),
    stockDate: number,
    syndicationType: enumValue(SyndicationType),
    lastUpdatedDate: number,
  },
  {
    extraFields: ExtraFields.Ignore,
  },
);

export type VehicleSummary = Pick<
  VehicleDbModel,
  | 'brand'
  | 'model'
  | 'vin'
  | 'registrationDate'
  | 'modelYear'
  | 'transmission'
  | 'engine'
>;

export const decodeVehicleSummary = object<VehicleSummary>(
  {
    brand: enumValue(AvailableBrands),
    model: decodeVehicleModel,
    vin: string,
    registrationDate: number,
    modelYear: number,
    transmission: decodeTransmission,
    engine: decodeVehicleEngine,
  },
  {
    extraFields: ExtraFields.Ignore,
  },
);

export type VehicleListingViewSummary = Omit<
  VehicleListingSummary,
  'dealer' | 'vehicle'
> & {
  dealer: DealerSummary;
  vehicle: VehicleSummary;
};

export const decodeVehicleListingViewSummary =
  object<VehicleListingViewSummary>(
    {
      ...decodeVehicleListingProps(number),
      dealer: decodeDealerSummary,
      vehicle: decodeVehicleSummary,
    },
    {
      extraFields: ExtraFields.Ignore,
      undefinedFields: UndefinedFields.Strip,
    },
  );

export const makeVehicleListingViewSummary = (
  vehicleListingView: VehicleListingViewDbModel,
): VehicleListingViewSummary => {
  return {
    id: vehicleListingView.id,
    currency: vehicleListingView.currency,
    price: vehicleListingView.price,
    odometer: vehicleListingView.odometer,
    stockDate: vehicleListingView.stockDate,
    syndicationType: vehicleListingView.syndicationType,
    lastUpdatedDate: vehicleListingView.lastUpdatedDate,
    liveStatus: vehicleListingView.liveStatus,
    isLive: vehicleListingView.isLive,
    dealer: {
      id: vehicleListingView.dealer.id,
      name: vehicleListingView.dealer.name,
      countryCode: vehicleListingView.dealer.countryCode,
      countryName: vehicleListingView.dealer.countryName,
      region: vehicleListingView.dealer.region,
      email: vehicleListingView.dealer.email,
      brand: vehicleListingView.dealer.brand,
      point: vehicleListingView.dealer.point,
    },
    vehicle: {
      brand: vehicleListingView.vehicle.brand,
      model: {
        id: vehicleListingView.vehicle.model.id,
        name: vehicleListingView.vehicle.model.name,
        code: vehicleListingView.vehicle.model.code,
        groupName: vehicleListingView.vehicle.model.groupName,
        capCode: vehicleListingView.vehicle.model.capCode,
        capId: vehicleListingView.vehicle.model.capId,
      },
      vin: vehicleListingView.vehicle.vin,
      registrationDate: vehicleListingView.vehicle.registrationDate,
      modelYear: vehicleListingView.vehicle.modelYear,
      transmission: vehicleListingView.vehicle.transmission,
      engine: vehicleListingView.vehicle.engine,
    },
  };
};
