import {
  array,
  boolean,
  choose,
  enumValue,
  is,
  jsonDate,
  maybe,
  number,
  object,
  text,
} from '@fmtk/decoders';
import {
  BidRemarketingAction,
  decodeBidRemarketingAction,
} from './RemarketingAction.js';
import {
  CurrentBid,
  CurrentOffer,
  RemarketingType,
  RemarketingVehicleStatus,
  decodeCurrentBid,
  decodeCurrentOffer,
} from './RemarketingVehicle.js';
import { UserDocument, decodeUserDocument } from './UserDocument.js';
import {
  Vehicle,
  VehicleOdometer,
  decodeVehicle,
  decodeVehicleOdometer,
} from './Vehicle.js';
import { VehicleMedia, decodeVehicleMedia } from './VehicleMedia.js';

export interface UserRemarketingVehicleBase {
  id: string;
  vehicle: Vehicle;
  countries: string[];
  status: RemarketingVehicleStatus;
  adminPrincipalId: string;
  vehicleMedia: VehicleMedia[];
  notes?: string;
  termsAndConditions?: UserDocument;
  shortVin?: string;
  registrationPlate?: string;
  inWatchList?: boolean;
  visibilityDate: number;
  odometer?: VehicleOdometer;
  currency?: string;
}

export enum BidStatus {
  Leading = 'Leading',
  OutBid = 'OutBid',
  NoBid = 'NoBid',
  HasBidders = 'HasBidders',
}

export enum ReservePriceStatus {
  Met = 'Met',
  NotMet = 'NotMet',
  NoReserve = 'NoReserve',
}

export interface UserAuctionRemarketingVehicle
  extends UserRemarketingVehicleBase {
  startPrice: number;
  type: RemarketingType.AUCTION;
  bidStatus: BidStatus;
  reservePriceMet: boolean;
  currentBid?: CurrentBid; // if the dealer is leading
  biddingHistory?: BidRemarketingAction[];
  leadingBid?: number;
  startingDate: number;
  closingDate: number;
  reservePriceStatus?: ReservePriceStatus;
  currency: string;
  odometer: VehicleOdometer;
}

export interface UserFixedPriceRemarketingVehicle
  extends UserRemarketingVehicleBase {
  type: RemarketingType.FIXED_PRICE;
  price: number;
  startingDate: number;
  closingDate?: number;
  odometer: VehicleOdometer;
  acceptsOffers?: boolean;
  currentOffer?: CurrentOffer;
  currency: string;
}

export interface UserUnassignedRemarketingVehicle
  extends UserRemarketingVehicleBase {
  type: RemarketingType.UNASSIGNED;
}

export type UserRemarketingVehicle =
  | UserAuctionRemarketingVehicle
  | UserFixedPriceRemarketingVehicle
  | UserUnassignedRemarketingVehicle;

export const userRemarketingVehicleBaseDecoders = {
  id: text,
  vehicle: decodeVehicle(jsonDate),
  countries: array(text),
  status: enumValue(RemarketingVehicleStatus),
  adminPrincipalId: text,
  vehicleMedia: array(decodeVehicleMedia(jsonDate)),
  shortVin: maybe(text),
  registrationPlate: maybe(text),
  notes: maybe(text),
  termsAndConditions: maybe(decodeUserDocument),
  inWatchList: maybe(boolean),
  visibilityDate: number,
  odometer: maybe(decodeVehicleOdometer),
  currency: maybe(text),
};

export const decodeUserRemarketingVehicleBase =
  object<UserRemarketingVehicleBase>(userRemarketingVehicleBaseDecoders);

export const decodeUserAuctionRemarketingVehicle =
  object<UserAuctionRemarketingVehicle>({
    ...userRemarketingVehicleBaseDecoders,
    startPrice: number,
    type: is(RemarketingType.AUCTION),
    currentBid: maybe(decodeCurrentBid),
    bidStatus: enumValue(BidStatus),
    reservePriceMet: boolean,
    biddingHistory: maybe(array(decodeBidRemarketingAction)),
    leadingBid: maybe(number),
    visibilityDate: number,
    startingDate: number,
    closingDate: number,
    odometer: decodeVehicleOdometer,
    reservePriceStatus: maybe(enumValue(ReservePriceStatus)),
    currency: text,
  });

export const decodeUserFixedPriceRemarketingVehicle =
  object<UserFixedPriceRemarketingVehicle>({
    ...userRemarketingVehicleBaseDecoders,
    type: is(RemarketingType.FIXED_PRICE),
    price: number,
    visibilityDate: number,
    startingDate: number,
    closingDate: maybe(number),
    odometer: decodeVehicleOdometer,
    acceptsOffers: maybe(boolean),
    currentOffer: maybe(decodeCurrentOffer),
    currency: text,
  });

export const decodeUserUnassignedRemarketingVehicle =
  object<UserUnassignedRemarketingVehicle>({
    ...userRemarketingVehicleBaseDecoders,
    type: is(RemarketingType.UNASSIGNED),
    visibilityDate: number,
  });

export const decodeUserRemarketingVehicle = choose(
  decodeUserAuctionRemarketingVehicle,
  decodeUserFixedPriceRemarketingVehicle,
  decodeUserUnassignedRemarketingVehicle,
);
