import { maybe } from '@fmtk/decoders';
import { method, service } from '../util/ServiceDef.js';
import { UserPermissions } from '../util/UserPermissions.js';
import { decodeGetBrandConfigRequest } from './getBrandConfig/GetBrandConfigRequest.js';
import { decodeGetBrandConfigResponse } from './getBrandConfig/GetBrandConfigResponse.js';
import { decodeListRegionCountryRequest } from './listRegionCountry/ListRegionCountryRequest.js';
import { decodeListRegionCountryResponse } from './listRegionCountry/ListRegionCountryResponse.js';
import { decodeListRegionsRequest } from './listRegions/ListRegionsRequest.js';
import { decodeListRegionsResponse } from './listRegions/ListRegionsResponse.js';

export const BrandServiceDef = service({
  getBrandConfig: method(
    decodeGetBrandConfigRequest,
    maybe(decodeGetBrandConfigResponse),
    [UserPermissions.Basic],
  ),
  listRegions: method(decodeListRegionsRequest, decodeListRegionsResponse, [
    UserPermissions.Basic,
  ]),
  listRegionCountry: method(
    decodeListRegionCountryRequest,
    decodeListRegionCountryResponse,
    [UserPermissions.Basic],
  ),
});

export type BrandServiceDef = typeof BrandServiceDef;
