import { Button, Link, Stack, Typography } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import {
  ChangeEvent,
  FunctionComponent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ListRegionCountryResponse } from '../../../../../api/BrandService/listRegionCountry/ListRegionCountryResponse.js';
import { AddRemarketingVehicleRequest } from '../../../../../api/RemarketingService/addRemarketingVehicle/AddRemarketingVehicleRequest.js';
import { GetVehicleForRemarketingCode } from '../../../../../api/RemarketingService/getVehicleForRemarketing/GetVehicleForRemarketingResponse.js';
import { Vehicle } from '../../../../../api/util/Vehicle.js';
import DetailsSkeleton from '../../../../common-ui/components/DetailsSkeleton.js';
import SearchInput from '../../../../common-ui/components/Filters/SearchInput.js';
import TableOfContents, {
  ContentItem,
} from '../../../../common-ui/components/TableOfContents.js';
import { ErrorState } from '../../../../common-ui/index.js';
import { useApiClient } from '../../../../hooks/useApiClient.js';
import { useAsyncState } from '../../../../hooks/useAsyncState.js';
import { useBrand } from '../../../../hooks/useBrand.js';
import { useCurrentUser } from '../../../../hooks/useCurrentUser.js';
import { useMobile } from '../../../../hooks/useMobile.js';
import { MAIN_CONTAINER_ID } from '../../../layout/MainLayout.js';
import {
  addRemarketingVehicleAtom,
  regionCountryOptionsAtom,
} from '../../../state/atoms.js';
import VehicleDetailsItem from '../../AddVehicle/VehicleDetailsItem.js';
import VehicleDetailsItemSkeleton from '../../AddVehicle/VehicleDetailsItemSkeleton.js';
import Section from '../../Section.js';

const VinStep: FunctionComponent = () => {
  const { t } = useTranslation();
  const mobile = useMobile();
  const [searchValue, setSearchValue] = useState<string>('');
  const [vehicle, setVehicle] = useAsyncState<Vehicle>();
  const [messageType, setMessageType] =
    useState<GetVehicleForRemarketingCode>();
  const { currentBrand, brandConfig } = useBrand();
  const currentUser = useCurrentUser();
  const api = useApiClient();
  const [regions, setRegions] = useAsyncState<ListRegionCountryResponse>();
  const setRegionOptions = useSetAtom(regionCountryOptionsAtom);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { brand } = useParams();
  const [addVehicleState, setAddVehicleState] = useAtom(
    addRemarketingVehicleAtom,
  );

  const contentItems: ContentItem[] = [
    {
      text: t('pages.addRemarketingVehicle.vin.search'),
      hash: 'search',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.addRemarketingVehicle.vin.details'),
      hash: 'details',
      sectionElementRef: useRef(null),
    },
  ];

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleSearch = (event: KeyboardEvent<HTMLDivElement>) => {
    if (searchValue !== '' && event.key === 'Enter' && currentBrand) {
      setVehicle(async () => {
        const response = await api.remarketing.getVehicleForRemarketing({
          brand: currentBrand,
          vin: searchValue,
        });

        setMessageType(response.code);

        setAddVehicleState((state) => ({
          ...state,
          selectedVehicle: undefined,
        }));

        return response.vehicle;
      });
    }
  };

  const handleSelect = () => {
    const ele = document.getElementById(MAIN_CONTAINER_ID);
    if (ele) {
      ele.scrollTo(0, 0);
    }

    if (!selectedVehicle) {
      return;
    }

    setAddVehicleState((state) => {
      return {
        ...state,
        currentRemarketingVehicle: {
          ...state.currentRemarketingVehicle,
          vehicle: selectedVehicle,
          currency: brandConfig?.currency || 'GBP',
          brand: currentBrand,
        } as AddRemarketingVehicleRequest,
      };
    });

    navigate(`${pathname}/details`);
  };

  const handleReset = () => {
    setSearchValue('');
  };

  const selectedVehicle = useMemo(
    () => addVehicleState.currentRemarketingVehicle?.vehicle || vehicle.value,
    [addVehicleState.currentRemarketingVehicle?.vehicle, vehicle.value],
  );

  const canSelect = selectedVehicle && messageType === undefined;

  useEffect(() => {
    if (!currentBrand) {
      return;
    }
    setRegions(async () => {
      const listRegionCountry = await api.brand.listRegionCountry({
        brand: currentBrand,
        regions: currentUser.value?.policy.RemarketingAdmin as string[],
      });

      setRegionOptions(listRegionCountry);

      return listRegionCountry;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, currentBrand, currentUser]);

  if (regions.loading) {
    return <DetailsSkeleton />;
  }

  return (
    <Stack
      direction={{
        sm: 'column-reverse',
        md: 'row',
      }}
      spacing={1}
    >
      <Stack flex={1}>
        <Section
          title={
            <Stack direction="row" justifyContent="space-between">
              <Typography textTransform="uppercase" variant="subtitle1">
                {t('vinNumber')}
              </Typography>
              {mobile && (
                <Link
                  component={RouterLink}
                  to={`/${brand}/remarketing/management`}
                  variant="button"
                >
                  {t('cancel')}
                </Link>
              )}
            </Stack>
          }
        >
          <div
            id={contentItems[0].hash}
            ref={
              contentItems[0]
                .sectionElementRef as React.RefObject<HTMLDivElement>
            }
          >
            <Stack mt={2} spacing={1.5}>
              <SearchInput
                error={messageType === GetVehicleForRemarketingCode.NotFound}
                fullWidth
                loading={vehicle.loading}
                onChange={handleInputChange}
                onKeyDown={handleSearch}
                onResetClick={handleReset}
                placeholder={t(
                  'pages.addRemarketingVehicle.vin.searchPlaceholder',
                )}
                size="small"
                value={searchValue}
              />
              {messageType && (
                <Typography
                  color="error"
                  fontWeight="medium"
                  variant="subtitle2"
                >
                  {t(`pages.addRemarketingVehicle.vin.${messageType}`)}
                </Typography>
              )}
            </Stack>
          </div>
          <Stack my={3}>
            {vehicle.error ? (
              <ErrorState />
            ) : vehicle.loading ? (
              <VehicleDetailsItemSkeleton />
            ) : (
              canSelect && (
                <VehicleDetailsItem
                  contentItem={contentItems[1]}
                  vehicle={selectedVehicle}
                />
              )
            )}
          </Stack>
          {canSelect && (
            <Stack direction="row-reverse">
              <Button
                disabled={vehicle.loading}
                onClick={handleSelect}
                variant="contained"
              >
                {t('select')}
              </Button>
            </Stack>
          )}
        </Section>
      </Stack>
      <div>
        <Section isSticky>
          <Stack>
            <TableOfContents
              items={contentItems}
              offsetPx={-80}
              scrollingElementSelector={MAIN_CONTAINER_ID}
            />
          </Stack>
        </Section>
      </div>
    </Stack>
  );
};

export default VinStep;
