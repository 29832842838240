import {
  ExtraFields,
  array,
  boolean,
  chain,
  enumValue,
  maybe,
  number,
  object,
  record,
  string,
  text,
} from '@fmtk/decoders';
import {
  AvailableBrands,
  PriceType,
  SyndicationType,
} from '../../api/util/index.js';
import { isUrl } from '../../api/util/isUrl.js';
import { SaveVehicleRule, decodeSaveVehicleRule } from './SaveVehicleRule.js';
import { VehicleFields } from './VehicleFields.js';
import { PdfConfigType, Report } from './pdfConfig.js';

export enum Reports {
  AddedRemovedNewCars = 'AddedRemovedNewCars',
  LiveStatusChanges = 'LiveStatusChanges',
}

export enum Language {
  en_gb = 'en-GB',
  cs_cz = 'cs-CZ',
  de_at = 'de-AT',
  de_de = 'de-DE',
  es_es = 'es-ES',
  fr_be = 'fr-BE',
  fr_fr = 'fr-FR',
  it_it = 'it-IT',
  ja_jp = 'ja-JP',
  ko_kr = 'ko-KR',
  nl_be = 'nl-BE',
  nl_nl = 'nl-NL',
  pl_pl = 'pl-PL',
  pt_pt = 'pt-PT',
  ru_ru = 'ru-RU',
  sk_sk = 'sk-SK',
  zh_cn = 'zh-CN',
  zh_tw = 'zh-TW',
}

export enum PDFTypes {
  CompactPortrait = 'CompactPortrait',
  CompactLandscape = 'CompactLandscape',
  OptionsPortrait = 'OptionsPortrait',
  OptionsLandscape = 'OptionsLandscape',
  DetailedPortrait = 'DetailedPortrait',
  DetailedPortraitGallery = 'DetailedPortraitGallery',
}

//Please add as you need to control access to the options for each Brand
const Enums = {
  SyndicationTypes: SyndicationType,
};

export type Options = {
  [key in keyof typeof Enums]?: Array<keyof (typeof Enums)[key]>;
};

export enum FeatureFlags {
  AddFeatureCategory = 'AddFeatureCategory',
}

export enum LocatorUrlTypes {
  AVL = 'avl',
  NVL = 'nvl',
  PREVIEW = 'preview',
  REMARKETING = 'remarketing',
}

export type LocatorUrlMarket = {
  [K: string]: string;
};

export type LocatorUrl = Partial<Record<LocatorUrlTypes, LocatorUrlMarket>>;

export const decodeLocatorUrlMarket = record(text, text);

export const decodeLocatorUrl = object<LocatorUrl>({
  avl: maybe(decodeLocatorUrlMarket),
  nvl: maybe(decodeLocatorUrlMarket),
  preview: maybe(decodeLocatorUrlMarket),
  remarketing: maybe(decodeLocatorUrlMarket),
});

export enum DefaultCurrency {
  EUR = 'EUR',
  GBP = 'GBP',
}

export const decodePdfConfigType = object<PdfConfigType>({
  certifiedLogo: maybe(text),
  fileName: text,
  noVehicleImage: text,
  othersLabel: maybe(text),
  title: text,
  showGallery: maybe(boolean),
});

export const decodeReport = object<Report>({
  CompactLandscape: maybe(decodePdfConfigType),
  CompactPortrait: maybe(decodePdfConfigType),
  DetailedPortrait: maybe(decodePdfConfigType),
  OptionsLandscape: maybe(decodePdfConfigType),
  OptionsPortrait: maybe(decodePdfConfigType),
  DetailedPortraitGallery: maybe(decodePdfConfigType),
});

export interface NamedImage {
  label: string;
  position: number;
  placeHolderUrl?: string;
  required?: boolean;
  bgReplacementConfiguration?: string;
}

export const decodeNamedImage = object<NamedImage>({
  label: text,
  position: number,
  placeHolderUrl: maybe(text),
  required: maybe(boolean),
  bgReplacementConfiguration: maybe(text),
});

export interface TransformationImage {
  label: string;
  name: string;
}

export const decodeTransformation = object<TransformationImage>({
  label: text,
  name: text,
});

export interface VehicleMediaConfig {
  minimumSecondaryShots?: number;
  photographyGuidelines?: string;
  primaryImages?: NamedImage[];
  secondaryImages?: NamedImage[];
  transformations?: TransformationImage[];
}

export const decodeVehicleMediaConfig = object<VehicleMediaConfig>({
  minimumSecondaryShots: maybe(number),
  photographyGuidelines: maybe(text),
  primaryImages: maybe(array(decodeNamedImage)),
  secondaryImages: maybe(array(decodeNamedImage)),
  transformations: maybe(array(decodeTransformation)),
});

export interface Remarketing {
  regions: string[];
  emailFooterText?: string;
  senderEmailAddress?: string;
}

export const decodeRemarketing = object<Remarketing>({
  regions: array(text),
  emailFooterText: maybe(text),
  senderEmailAddress: maybe(text),
});

export enum OnboardingSteps {
  Features = 'features',
}

export interface AddListingConfig {
  steps: OnboardingSteps[];
  allowAddManually?: boolean;
  vehicleFields?: VehicleFields[];
}

export const decodeAddListingConfig = object<AddListingConfig>({
  steps: array(enumValue(OnboardingSteps)),
  allowAddManually: maybe(boolean),
  vehicleFields: maybe(array(enumValue(VehicleFields))),
});

export type ArchiveListingConfig = {
  archiveAfterDays?: number;
};

export const decodeArchiveListingConfig = object<ArchiveListingConfig>({
  archiveAfterDays: maybe(number),
});

export type KioskConfig = {
  infoSlide?: {
    interval: number;
    default: string;
    options?: {
      [language: string]: string;
    };
  };
};

const decodeInfoSlide = object<{
  interval: number;
  default: string;
  options?: { [language: string]: string };
}>({
  default: string,
  interval: number,
  options: maybe(record(string, string)),
});

export const decodeKioskConfig = object<KioskConfig>({
  infoSlide: maybe(decodeInfoSlide),
});

export interface BrandConfig {
  archiveListingConfig?: ArchiveListingConfig;
  availablePdfReports?: Report;
  carMovementReportMonthInterval?: number;
  certifiedLogo?: string;
  cloudinaryBrandUploadPreset?: string;
  currency?: DefaultCurrency;
  dealerTNCLink?: string;
  disabledOptions?: Options;
  disabledPriceOnApplication?: boolean;
  disabledReports?: Reports[];
  disablePrices?: PriceType[];
  featureFlags?: FeatureFlags[];
  locatorAnalyticsUrl?: string;
  locatorUrl?: LocatorUrl;
  logoUrl?: string;
  noVehicleImage?: string;
  showStaffAreaLink?: boolean;
  vehicleMedia?: VehicleMediaConfig;
  defaultFeatures?: string[];
  saveVehicleRule?: SaveVehicleRule;
  remarketing?: Remarketing;
  remarketingBidStep?: number;
  remarketingBidMinutesWindow?: number;
  availableLanguages?: Language[];
  addListing?: AddListingConfig;
  editableVehicleFields?: VehicleFields[];
  vehicleFields?: VehicleFields[];
  emailHeaderLogo?: string;
  showPriceExcludesVat?: boolean;
  showVatQualifying?: boolean;
  kiosk?: KioskConfig;
}

export type BrandAssets = {
  certifiedLogo?: string;
  logoUrl?: string;
  noVehicleImage?: string;
};

export const decodeBrandConfigOptions = object<Options>({
  SyndicationTypes: maybe(array(enumValue(SyndicationType))),
});

export const decodeBrandConfig = object<BrandConfig>(
  {
    archiveListingConfig: maybe(decodeArchiveListingConfig),
    availablePdfReports: maybe(decodeReport),
    carMovementReportMonthInterval: maybe(number),
    certifiedLogo: maybe(text),
    cloudinaryBrandUploadPreset: maybe(text),
    currency: maybe(enumValue(DefaultCurrency)),
    dealerTNCLink: maybe(chain(text, isUrl)),
    disabledOptions: maybe(decodeBrandConfigOptions),
    disabledPriceOnApplication: maybe(boolean),
    disabledReports: maybe(array(enumValue(Reports))),
    disablePrices: maybe(array(enumValue(PriceType))),
    featureFlags: maybe(array(enumValue(FeatureFlags))),
    locatorAnalyticsUrl: maybe(text),
    locatorUrl: maybe(decodeLocatorUrl),
    logoUrl: maybe(text),
    noVehicleImage: maybe(text),
    showStaffAreaLink: maybe(boolean),
    vehicleMedia: maybe(decodeVehicleMediaConfig),
    defaultFeatures: maybe(array(text)),
    saveVehicleRule: maybe(decodeSaveVehicleRule),
    remarketing: maybe(decodeRemarketing),
    availableLanguages: maybe(array(enumValue(Language))),
    addListing: maybe(decodeAddListingConfig),
    vehicleFields: maybe(array(enumValue(VehicleFields))),
    editableVehicleFields: maybe(array(enumValue(VehicleFields))),
    remarketingBidStep: maybe(number),
    remarketingBidMinutesWindow: maybe(number),
    emailHeaderLogo: maybe(text),
    showPriceExcludesVat: maybe(boolean),
    showVatQualifying: maybe(boolean),
    kiosk: maybe(decodeKioskConfig),
  },
  // this is to prevent it from throwing an error when there are extra fields added but code has not been released yet
  { extraFields: ExtraFields.Ignore },
);

export const config: Partial<Record<AvailableBrands, BrandAssets>> = {
  [AvailableBrands.AstonMartin]: {
    logoUrl: 'https://res.cloudinary.com/motortrak/ims2/icons/brand-aston.svg',
    noVehicleImage:
      'https://res.cloudinary.com/motortrak/q_auto,f_auto/aston-martin/global/vehicle-awaiting.jpg',
    certifiedLogo:
      'https://res.cloudinary.com/motortrak/h_100,c_scale/aston-martin/global/timeless-srp-desktop.png',
  },
  [AvailableBrands.Bentley]: {
    logoUrl:
      'https://res.cloudinary.com/motortrak/ims2/icons/brand-bentley.svg',
    noVehicleImage:
      'https://res.cloudinary.com/motortrak/bentley/no-vehicle.jpg',
    certifiedLogo:
      'https://res.cloudinary.com/motortrak/bentley/global/bentley-certified.png',
  },
  [AvailableBrands.Genesis]: {
    logoUrl:
      'https://res.cloudinary.com/motortrak/ims2/icons/brand-genesis.svg',
    noVehicleImage:
      'https://res.cloudinary.com/motortrak/b_white/Placeholder%20Images/Placeholder',
  },
  [AvailableBrands.Kia]: {
    logoUrl: 'https://res.cloudinary.com/motortrak/ims2/icons/brand-kia.svg',
    noVehicleImage:
      'https://res.cloudinary.com/motortrak/b_white/Placeholder%20Images/Placeholder',
  },
  [AvailableBrands.Lamborghini]: {
    logoUrl: 'https://res.cloudinary.com/motortrak/ims2/icons/brand-lambo.svg',
    noVehicleImage:
      'https://res.cloudinary.com/motortrak/b_white/Placeholder%20Images/Placeholder',
  },
  [AvailableBrands.RollsRoyce]: {
    logoUrl:
      'https://res.cloudinary.com/motortrak/ims2/icons/brand-rolls-royce.svg',
    noVehicleImage:
      'https://res.cloudinary.com/motortrak/Placeholder%20Images/PlaceholderRR.jpg',
  },
};
