import { merge } from 'lodash-es';

export interface AppConfig {
  api: string;
  inventoryManagementUrl: string;
  imsUrl: string;
  pusher: {
    appKey: string;
  };
}

const config = merge(
  {
    api: process.env.REACT_APP_CONFIG_API,
    imsUrl: process.env.REACT_APP_CONFIG_IMS,
    pusher: {
      appKey: process.env.REACT_APP_CONFIG_PUSHER_CLIENT_KEY,
    },
  },
  (window as any).app_config,
);

export function useAppConfig(): AppConfig {
  return config;
}
