import {
  enumValue,
  maybe,
  normaliseNumber,
  strToNum,
  text,
} from '@fmtk/decoders';
import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { OdometerUnit } from '../../../../../../api/util/OdometerUnit.js';
import { UserFixedPriceRemarketingVehicle } from '../../../../../../api/util/UserRemarketingVehicle.js';
import { normaliseDate } from '../../../../../../util/decoders/normaliseDate.js';
import sectionStyles from '../../../../../common-ui/components/sectionStyle.js';
import { CellTextInput, Form } from '../../../../../common-ui/index.js';
import useCountdown from '../../../../../hooks/useCountDown.js';
import { useCreateForm } from '../../../../../hooks/useCreateForm.js';
import { formatDateTime } from '../../../../../util/dates.js';
import { formatPrice } from '../../../../../util/formatters.js';

type FixedPriceDetailsProps = {
  vehicle: UserFixedPriceRemarketingVehicle;
};

const FixedPriceDetails: FunctionComponent<FixedPriceDetailsProps> = ({
  vehicle,
}) => {
  const { i18n } = useTranslation();

  const closingDate = vehicle.closingDate
    ? DateTime.fromMillis(vehicle.closingDate, {
        locale: i18n.language,
      })
    : undefined;

  const { inThePast, timeLeft } =
    useCountdown(closingDate, {
      stopOnZero: true,
      toRelativeOptions: {
        locale: i18n.language,
        unit: ['years', 'months', 'days', 'hours', 'minutes'],
      },
    }) || {};

  const form = useCreateForm(
    {
      timeLeft: maybe(text),
      endDate: maybe(normaliseDate()),
      odometer: maybe(normaliseNumber),
      shortVin: maybe(text),
      registrationPlate: maybe(text),
      recommendedRetailPrice: maybe(strToNum),
      odometerUnit: maybe(enumValue(OdometerUnit)),
    },
    () => void 0,
  );

  const [, formBind] = form;

  const resetForm = useCallback(() => {
    formBind.reset({
      timeLeft: timeLeft,
      endDate: vehicle.closingDate
        ? formatDateTime(DateTime.fromMillis(vehicle.closingDate).toJSDate())
        : '',
      odometer: vehicle.odometer?.value || '',
      odometerUnit: vehicle.odometer?.units || '',
      registrationPlate: vehicle.registrationPlate || '',
      shortVin: vehicle.shortVin || '',
      recommendedRetailPrice: vehicle.vehicle.rrp?.value
        ? formatPrice(vehicle.vehicle.rrp.value, vehicle.currency, {
            maximumFractionDigits: 2,
          })
        : '',
    });
  }, [formBind, vehicle, timeLeft]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <Form form={form} translations="pages.remarketingVehiclePage.auction.form">
      <Stack spacing={4}>
        <Stack sx={sectionStyles.sectionContent}>
          <Stack flex={1}>
            <CellTextInput label="endDate" name="endDate" />
            <CellTextInput label="shortVin" name="shortVin" />
            <CellTextInput
              label="recommendedRetailPrice"
              name="recommendedRetailPrice"
            />
          </Stack>
          <Stack flex={1}>
            {!inThePast && <CellTextInput label="timeLeft" name="timeLeft" />}
            <CellTextInput label="odometer" name="odometer" />
            <CellTextInput label="odometerUnit" name="odometerUnit" />
            <CellTextInput label="regPlate" name="registrationPlate" />
          </Stack>
        </Stack>
      </Stack>
    </Form>
  );
};

export default FixedPriceDetails;
