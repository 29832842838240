import { PriorityHigh } from '@mui/icons-material';
import {
  Box,
  ClickAwayListener,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dashIfUndefined } from '../../../util/dashIfUndefined.js';
import { sx } from '../../../util/sx.js';
import { useForm } from '../../context/FormContext.js';
import { FieldError, hasError } from '../FieldError.js';
import HighlightWrapper from '../HighlightWrapper.js';
import LightTooltip from '../LightTooltip.js';
import CellLabel from './CellLabel.js';

export interface CellProps {
  label: string;
  name: string;
  children?: React.ReactNode;
  readonly?: boolean;
  formattedValue?: string;
  htmlFor?: string;
  sx?: SxProps;
  required?: boolean;
  highlightChanges?: boolean;
  editOnly?: boolean;
}

const styles = sx({
  root: {
    minHeight: 52,
    borderBottom: 1,
    borderTop: 1,
    borderColor: '#e4eaf1',
    flexDirection: 'row',
  },

  hover: {
    position: 'relative',
    ':hover': {
      cursor: 'pointer',
    },
  },
});

export const Cell: FunctionComponent<CellProps> = ({
  label,
  readonly = true,
  name,
  children,
  formattedValue,
  htmlFor,
  sx,
  required,
  highlightChanges,
  editOnly,
}) => {
  const [{ errors, values }, , { translations }] = useForm();
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();
  const value = useMemo(() => {
    return dashIfUndefined(formattedValue || values[name] || '');
  }, [formattedValue, name, values]);

  // We need to be sure that if the field has errors it will be enabled to edit
  const disableField = useMemo(() => {
    return readonly && !hasError(errors[name]) && !isEditing;
  }, [errors, name, readonly, isEditing]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        setIsEditing(true);
      }
      if (['Tab', 'Escape'].includes(event.key) && isEditing) {
        event.preventDefault();
        setIsEditing(false);
      }
    },
    [isEditing],
  );

  return (
    <HighlightWrapper highlightChanges={highlightChanges} value={values[name]}>
      <Stack
        bgcolor={disableField ? 'background.default' : 'background.paper'}
        sx={styles.root}
      >
        <Stack
          alignItems="center"
          direction="row"
          flex={1}
          justifyContent="space-between"
          maxWidth="230px"
          paddingLeft={2}
          py={1.5}
        >
          <CellLabel
            htmlFor={htmlFor}
            label={
              translations
                ? t(`${translations}.labels.${name}`, {
                    defaultValue: label,
                  })
                : label
            }
          />
          {(hasError(errors[name]) || (required && !values[name])) && (
            <LightTooltip
              title={
                (
                  <FieldError
                    error={errors[name] ?? 'required'}
                    translationBase={translations && `${translations}.errors`}
                  />
                ) || ''
              }
            >
              <PriorityHigh color="error" sx={{ width: 18 }} />
            </LightTooltip>
          )}
        </Stack>
        <Stack flex={1} justifyContent="center">
          {disableField ? (
            <Typography
              color="#626F86"
              py={1.5}
              sx={sx}
              textAlign="left"
              variant="cell"
            >
              {value}
            </Typography>
          ) : (
            <ClickAwayListener
              onClickAway={() => {
                setIsEditing(false);
              }}
            >
              <Box
                alignItems="center"
                component="div"
                display="flex"
                minHeight={50}
                onClick={() => setIsEditing(true)}
                onKeyDown={handleKeyDown}
                sx={styles.hover}
                tabIndex={1}
              >
                {isEditing || editOnly ? (
                  <>{children}</>
                ) : (
                  <Typography
                    color="#172B4D"
                    py={1.5}
                    textAlign="left"
                    variant="subtitle2"
                  >
                    {value}
                  </Typography>
                )}
              </Box>
            </ClickAwayListener>
          )}
        </Stack>
      </Stack>
    </HighlightWrapper>
  );
};

export default Cell;
