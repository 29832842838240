import {
  boolean,
  enumValue,
  maybe,
  normaliseNumber,
  strToNum,
  text,
} from '@fmtk/decoders';
import { Box, Button, Collapse, Paper, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { AvailableBrands } from '../../../../api/util/AvailableBrands.js';
import { HandDrive } from '../../../../api/util/HandDrive.js';
import {
  FuelType,
  Vehicle,
  getFuelTypeDisplayName,
} from '../../../../api/util/Vehicle.js';
import { normaliseDate } from '../../../../util/decoders/normaliseDate.js';
import { ContentItem } from '../../../common-ui/components/TableOfContents.js';
import { Form } from '../../../common-ui/index.js';
import { useCreateForm } from '../../../hooks/useCreateForm.js';
import { useDealer } from '../../../hooks/useDealer.js';
import { filterFalsey } from '../../../util/filterFalsey.js';
import { sx } from '../../../util/sx.js';
import Colour from '../Vehicle/Sections/Colour.js';
import Engine from '../Vehicle/Sections/Engine.js';
import Features from '../Vehicle/Sections/Features.js';
import KeyInformation from '../Vehicle/Sections/KeyInformation.js';
import { YesNo } from '../Vehicle/Sections/types.js';

interface VehicleDetailsItemProps {
  vehicle: Vehicle;
  contentItem?: ContentItem;
}

export const styles = sx({
  container: {
    bgcolor: 'background.default',
    p: 2,
  },
  sectionItem: {
    sx: {
      m: 0,
    },
  },
  sections: {
    bgcolor: 'paper.default',
    mt: 2,
    p: 2,
  },
});

const ItemRow = ({ label, value }: { label: string; value?: string }) => {
  return (
    <Stack
      direction={{
        xs: 'column',
        sm: 'row',
      }}
      spacing={{
        xs: 0,
        sm: 2,
      }}
    >
      <Box minWidth={80}>
        <Typography color="#6B778C" fontWeight="medium" variant="body2">
          {`${label}:`}
        </Typography>
      </Box>
      <Box>
        <Typography color="#0C264E" fontWeight="regular" variant="body2">
          {value || '-'}
        </Typography>
      </Box>
    </Stack>
  );
};

const VehicleDetailsItem: FunctionComponent<VehicleDetailsItemProps> = ({
  vehicle,
  contentItem,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const { t } = useTranslation();
  const { currentDealer } = useDealer();
  const { subtitle, fuelType } = useMemo(() => {
    const fuelType = !!vehicle.engine?.fuelType
      ? getFuelTypeDisplayName(
          vehicle.engine.fuelType.toUpperCase() as FuelType,
        )
      : undefined;
    return {
      subtitle: [
        vehicle.modelYear,
        vehicle.variant,
        vehicle.bodyStyle?.name,
        fuelType,
      ]
        .filter(Boolean)
        .join(' | '),
      fuelType,
    };
  }, [vehicle]);

  const handleDetails = useCallback(() => {
    setShowDetails((state) => !state);
  }, []);

  const form = useCreateForm(
    {
      bodyStyle: maybe(text),
      capacity: maybe(normaliseNumber),
      currency: maybe(text),
      drive: maybe(enumValue(HandDrive)),
      engineType: maybe(text),
      fuelType: maybe(text),
      exteriorColour: maybe(text),
      german25aTaxSalesDisclaimer: maybe(enumValue(YesNo)),
      vatQualifying: maybe(enumValue(YesNo)),
      priceExcludesVat: maybe(enumValue(YesNo)),

      interiorColour: maybe(text),
      secondaryExteriorColour: maybe(text),
      secondaryInteriorColour: maybe(text),
      capCode: maybe(text),
      capId: maybe(normaliseNumber),

      model: text,
      modelYear: maybe(normaliseNumber),
      netPrice: maybe(strToNum),
      price: maybe(strToNum),
      basePrice: maybe(strToNum),
      priceLessTaxes: maybe(strToNum),
      priceOnApplication: maybe(boolean),

      registrationDate: maybe(normaliseDate()),
      retailPricePlusTax: maybe(strToNum),
      standInValue: maybe(strToNum),

      trade: maybe(strToNum),
      transmission: text,
      variant: maybe(text),
      vin: text,

      collection: maybe(text),
      lfsModelCode: maybe(text),
    },
    () => void 0,
  );

  const [, formBind] = form;

  useEffect(() => {
    if (!vehicle) {
      return;
    }

    formBind.reset({
      //Key information
      vin: vehicle.vin || '',
      model: vehicle.model?.name || '',
      variant: vehicle.variant || '',
      bodyStyle: vehicle.bodyStyle?.name || '',
      drive: vehicle.handDrive || '',
      registrationDate: vehicle?.registrationDate
        ? DateTime.fromJSDate(vehicle?.registrationDate).toISODate()
        : '',
      modelYear: vehicle.modelYear || '',
      capCode: vehicle?.model.capCode,
      capId: vehicle?.model.capId,

      //Engine
      engineType: vehicle.engine?.description || '',
      capacity: vehicle.engine?.capacity || '',
      transmission: vehicle.transmission?.name || '',
      fuelType: fuelType || '',

      //Price
      currency: currentDealer?.currency || '',
      price: '',
      trade: '',
      standInValue: '',
      basePrice: '',
      priceLessTaxes: '',
      retailPricePlusTax: '',
      netPrice: '',
      german25aTaxSalesDisclaimer: YesNo.No,
      priceOnApplication: false,
      vatQualifying: YesNo.No,
      priceExcludesVat: YesNo.No,

      //Colour
      exteriorColour: vehicle?.appearanceOptions?.exteriorColour || '',
      interiorColour: vehicle?.appearanceOptions?.interiorColour || '',
      secondaryExteriorColour:
        vehicle?.appearanceOptions?.secondaryExteriorColour || '',
      secondaryInteriorColour:
        vehicle?.appearanceOptions?.secondaryInteriorColour || '',

      // Brand-specific
      collection:
        vehicle?.brandSpecific?.[AvailableBrands.RollsRoyce]?.collection || '',
      lfsModelCode:
        vehicle?.brandSpecific?.[AvailableBrands.Lamborghini]?.lfsModelCode ||
        '',
    });
  }, [currentDealer, formBind, fuelType, vehicle]);

  return (
    <Paper sx={styles.container}>
      <Stack direction="column" spacing={1}>
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          justifyContent="space-between"
          spacing={3}
        >
          <Stack flex={1} spacing={1}>
            <Typography color="#0C264E" variant="subtitle1">
              {vehicle.model.name || '-'}
            </Typography>
            <Typography color="#0C264E" fontWeight="medium" variant="body2">
              {subtitle}
            </Typography>
            <Stack>
              <ItemRow
                label={t('exterior')}
                value={filterFalsey([
                  vehicle.appearanceOptions?.exteriorColour,
                  vehicle.appearanceOptions?.secondaryExteriorColour,
                ]).join(' | ')}
              />
              <ItemRow
                label={t('interior')}
                value={filterFalsey([
                  vehicle.appearanceOptions?.interiorColour,
                  vehicle.appearanceOptions?.secondaryInteriorColour,
                ]).join(' | ')}
              />
            </Stack>
          </Stack>
          <Stack
            alignSelf={{
              xs: 'flex-start',
              sm: 'flex-end',
            }}
          >
            <Button onClick={handleDetails} variant="outlined">
              {t(showDetails ? 'hideDetails' : 'viewDetails')}
            </Button>
          </Stack>
        </Stack>
        <div
          id={contentItem?.hash}
          ref={
            contentItem?.sectionElementRef as React.RefObject<HTMLDivElement>
          }
        >
          <Form form={form} translations="pages.vehiclePage.details.form">
            <Collapse in={showDetails}>
              <Paper sx={styles.sections}>
                <Stack spacing={4}>
                  <KeyInformation
                    readonly
                    sectionItemsProps={styles.sectionItem}
                    title={t('pages.vehiclePage.details.keyInformation')}
                  />
                  <Engine
                    readonly
                    sectionItemsProps={styles.sectionItem}
                    title={t('pages.vehiclePage.details.engine')}
                  />
                  <Colour
                    readonly
                    sectionItemsProps={styles.sectionItem}
                    title={t('pages.vehiclePage.details.colour')}
                  />
                  <Features
                    features={vehicle.features}
                    sectionItemsProps={styles.sectionItem}
                    title={t('features')}
                  />
                </Stack>
              </Paper>
            </Collapse>
          </Form>
        </div>
      </Stack>
    </Paper>
  );
};

export default VehicleDetailsItem;
