import {
  boolean,
  chain,
  Decoder,
  DecoderError,
  enumValue,
  error,
  is,
  maybe,
  normaliseNumber,
  ok,
  PropDecoders,
  strToNum,
  text,
} from '@fmtk/decoders';
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddAuctionVehicleRequest,
  AddFixedPriceVehicleRequest,
} from '../../../../api/RemarketingService/addRemarketingVehicle/AddRemarketingVehicleRequest.js';
import { OdometerUnit } from '../../../../api/util/OdometerUnit.js';
import { RemarketingType } from '../../../../api/util/RemarketingVehicle.js';
import { decodeUserDocument } from '../../../../api/util/UserDocument.js';
import { dateToNumber } from '../../../../util/decoders/dateToNumber.js';
import { minNumber } from '../../../../util/decoders/minNumber.js';
import { Common } from '../../../../util/index.js';
import { FormTextField, useForm } from '../../../common-ui/index.js';
import { FormBind, useBrand } from '../../../hooks/index.js';
import { sx } from '../../../util/index.js';
import TermsAndConditionsSection from '../RemarketingVehicle/Sections/TermsAndConditions.js';
import { dateTimeFormat } from '../RemarketingVehicle/types.js';

export type AddRemarketingPricedFormState<
  R extends AddAuctionVehicleRequest | AddFixedPriceVehicleRequest,
  T extends RemarketingType,
> = Omit<
  R,
  | 'notes'
  | 'countries'
  | 'brand'
  | 'vehicle'
  | 'vehicleMedia'
  | 'odometer'
  | 'shortVin'
  | 'registrationPlate'
> & {
  odometerValue: number;
  odometerUnit: OdometerUnit;
  type: T;
};

export type AddAuctionFormState = AddRemarketingPricedFormState<
  AddAuctionVehicleRequest,
  RemarketingType.AUCTION
>;

export type AddFixedPriceFormState = AddRemarketingPricedFormState<
  AddFixedPriceVehicleRequest,
  RemarketingType.FIXED_PRICE
>;

const baseTranslations = 'pages.addRemarketingVehicle.details';

const styles = sx({
  fieldContainer: {
    maxWidth: 300,
    flex: 'auto',
  },
  checkboxContainer: {
    pt: {
      sm: 2,
    },
  },
});

const dateDecoder = dateToNumber({ format: dateTimeFormat });

type PricedFormState = Common<AddAuctionFormState, AddFixedPriceFormState>;

const baseDecoder: PropDecoders<PricedFormState> = {
  visibilityDate: dateDecoder,
  currency: text,
  startingDate: dateDecoder,
  odometerValue: chain(
    normaliseNumber,
    minNumber({
      min: 0,
    }),
  ),
  odometerUnit: enumValue(OdometerUnit),
  termsAndConditions: maybe(decodeUserDocument),
};

export const addAuctionFormDecoder: PropDecoders<AddAuctionFormState> = {
  ...baseDecoder,
  closingDate: dateDecoder,
  reservePrice: chain(
    strToNum,
    minNumber({
      min: 1,
      error: `${baseTranslations}.form.errors.INVALID_PRICE`,
    }),
  ),
  startPrice: chain(
    strToNum,
    minNumber({
      min: 1,
      error: `${baseTranslations}.form.errors.INVALID_PRICE`,
    }),
  ),
  type: is(RemarketingType.AUCTION),
};

export const addFixedPriceFormDecoder: PropDecoders<AddFixedPriceFormState> = {
  ...baseDecoder,
  closingDate: maybe(dateDecoder),
  price: chain(
    strToNum,
    minNumber({
      min: 1,
      error: `${baseTranslations}.form.errors.INVALID_PRICE`,
    }),
  ),
  type: is(RemarketingType.FIXED_PRICE),
  acceptsOffers: boolean,
};

export const addPricedVehicleCrossFieldValidation: Decoder<
  AddAuctionFormState | AddFixedPriceFormState,
  AddAuctionFormState | AddFixedPriceFormState
> = (value) => {
  const errors: DecoderError[] = [];

  if (value.visibilityDate > value.startingDate) {
    errors.push({
      id: 'INVALID_VISIBILITY_DATE',
      text: 'visibilityDate',
      field: 'visibilityDate',
    });
  }

  if (value.closingDate && value.startingDate >= value.closingDate) {
    errors.push(
      {
        id: 'INVALID_STARTING_DATE',
        text: 'startingDate',
        field: 'startingDate',
      },
      {
        id: 'INVALID_CLOSING_DATE',
        text: 'closingDate',
        field: 'closingDate',
      },
    );
  }

  if (
    'reservePrice' in value &&
    'startPrice' in value &&
    value.reservePrice < value.startPrice
  ) {
    errors.push({
      id: 'INVALID_RESERVE_PRICE',
      text: 'reservePrice',
      field: 'reservePrice',
    });
  }

  if (errors.length) {
    return error(errors);
  }

  return ok(value);
};

export const AddRemarketingVehicleForm = () => {
  const [disabledReservePrice, setDisabledReservePrice] = useState(true);
  const { currentBrand } = useBrand();
  const { t } = useTranslation();

  const [formState, formBind] = useForm<
    AddAuctionFormState | AddFixedPriceFormState
  >();

  useEffect(() => {
    if (
      'startPrice' in formState.dirty &&
      'startPrice' in formState.values &&
      formState.dirty['startPrice'] &&
      disabledReservePrice
    ) {
      (formBind as FormBind<AddAuctionFormState>).setValue(
        'reservePrice',
        formState.values.startPrice,
      );
    }
  }, [formState.values, disabledReservePrice, formState.dirty, formBind]);

  const minClosingDate = formState.values?.startingDate
    ? DateTime.fromFormat(
        formState.values.startingDate.toString(),
        dateTimeFormat,
      )
        .plus({ hours: 1 })
        .toFormat(dateTimeFormat)
    : '';

  const timezone = DateTime.now().offsetNameShort;

  const type = formState.values.type;

  useEffect(() => {
    if (type === RemarketingType.FIXED_PRICE) {
      (formBind as FormBind<AddFixedPriceFormState>).setValue(
        'acceptsOffers',
        formState.values.acceptsOffers === true || false,
      );
    }
  }, [formBind, formState.values, type]);

  if (!currentBrand) {
    return null;
  }

  return (
    <Stack spacing={3}>
      <Stack spacing={3}>
        <Stack sx={styles.fieldContainer}>
          <FormTextField
            helperText={t(`${baseTranslations}.form.help.visibilityDate`)}
            label={t(`${baseTranslations}.form.labels.visibilityDate`)}
            max={formState.values.startingDate?.toString() || ''}
            name="visibilityDate"
            type="datetime-local"
          />
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          spacing={2}
        >
          <Stack sx={styles.fieldContainer}>
            <FormTextField
              helperText={t(`${baseTranslations}.form.help.startingDate`)}
              label={t(`${baseTranslations}.form.labels.startingDate`)}
              max={formState.values?.closingDate?.toString() || ''}
              name="startingDate"
              type="datetime-local"
            />
          </Stack>
          <Stack sx={styles.fieldContainer}>
            <FormTextField
              label={t(`${baseTranslations}.form.labels.closingDate`)}
              min={minClosingDate}
              name="closingDate"
              type="datetime-local"
            />
          </Stack>
        </Stack>
        <Typography
          color="#637381"
          fontWeight="fontWeightMedium"
          variant="body2"
        >
          {t(`${baseTranslations}.timezone`, { timezone })}
        </Typography>
      </Stack>
      {type === RemarketingType.AUCTION && (
        <>
          <Stack
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            spacing={2}
          >
            <Stack sx={styles.fieldContainer}>
              <FormTextField
                label={t(`${baseTranslations}.form.labels.startPrice`)}
                min="0"
                name="startPrice"
                type="number"
              />
            </Stack>
          </Stack>
          <Stack
            alignItems={{
              sm: 'center',
            }}
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            spacing={{
              xs: 0,
              sm: 2,
            }}
          >
            <Stack sx={styles.fieldContainer}>
              <FormTextField
                disabled={disabledReservePrice}
                label={t(`${baseTranslations}.form.labels.reservePrice`)}
                min="0"
                name="reservePrice"
                type="number"
              />
            </Stack>
            <Stack sx={styles.fieldContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={disabledReservePrice}
                    onChange={(_, checked) => {
                      setDisabledReservePrice(checked);
                    }}
                  />
                }
                label={t(
                  `${baseTranslations}.form.labels.disabledReservePrice`,
                )}
                sx={styles.checkboxContainer}
              />
            </Stack>
          </Stack>
        </>
      )}
      {type === RemarketingType.FIXED_PRICE && (
        <Stack
          alignItems={{ sm: 'center' }}
          direction={{ sm: 'row', xs: 'column' }}
          spacing={2}
        >
          <Stack sx={styles.fieldContainer}>
            <FormTextField
              fullWidth
              label={t(`${baseTranslations}.form.labels.price`)}
              min="0"
              name="price"
              type="number"
            />
          </Stack>
          <Stack sx={styles.fieldContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formState.values.acceptsOffers}
                  onChange={(_, checked) => {
                    (formBind as FormBind<AddFixedPriceFormState>).setValue(
                      'acceptsOffers',
                      checked,
                    );
                  }}
                />
              }
              label={t(`${baseTranslations}.form.labels.acceptsOffers`)}
              sx={styles.checkboxContainer}
            />
          </Stack>
        </Stack>
      )}
      <TermsAndConditionsSection
        brand={currentBrand}
        onSelectionChange={(doc) => {
          formBind.setValue('termsAndConditions', doc);
        }}
        title={t(`pages.remarketingVehiclePage.auction.termsAndConditions`)}
        value={formState.values.termsAndConditions}
      />
    </Stack>
  );
};
