/* eslint-disable react/jsx-sort-props */
import {
  array,
  chain,
  DecoderError,
  enumValue,
  error,
  jsonDate,
  maybe,
  normaliseNumber,
  ok,
  strToNum,
  text,
} from '@fmtk/decoders';
import { Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  OdometerUnit,
  odometerUnitOptions,
} from '../../../../api/util/OdometerUnit.js';
import {
  RemarketingType,
  RemarketingVehicle,
  RemarketingVehicleStatus,
} from '../../../../api/util/RemarketingVehicle.js';
import { minNumber } from '../../../../util/decoders/minNumber.js';
import CellDateTimeInput from '../../../common-ui/components/Cell/CellDateTimeInput.js';
import FormErrorsSummary from '../../../common-ui/components/FormErrorsSummary.js';
import LightTooltip from '../../../common-ui/components/LightTooltip.js';
import { RegionCountries } from '../../../common-ui/components/RegionCountrySelector/RegionSelector.js';
import TableOfContents, {
  ContentItem,
} from '../../../common-ui/components/TableOfContents.js';
import {
  CellMoneyInput,
  CellSelectInput,
  CellTextInput,
  ConfirmModal,
  Form,
} from '../../../common-ui/index.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useBrand } from '../../../hooks/useBrand.js';
import { useCreateForm } from '../../../hooks/useCreateForm.js';
import { useCurrency } from '../../../hooks/useCurrency.js';
import { filterFalsey } from '../../../util/filterFalsey.js';
import { useRemarketingEditor } from '../../hooks/useRemarketingEditor.js';
import { MAIN_CONTAINER_ID } from '../../layout/MainLayout.js';
import Section from '../Section.js';
import Comments from '../Vehicle/Sections/Comments.js';
import CancelRemarketingSection from './Sections/CancelRemarketing.js';
import Regions from './Sections/Regions.js';
import RemarketingDocumentsSection from './Sections/RemarketingDocuments.js';
import { dateTimeFormat, RemarketingVehicleType } from './types.js';

interface UnassignedProps {
  remarketingVehicle: RemarketingVehicle;
  onUpdate?: (remarketingVehicle: RemarketingVehicleType) => void;
  readonly?: boolean;
}

const Unassigned: FunctionComponent<UnassignedProps> = ({
  remarketingVehicle,
  onUpdate,
  readonly,
}): JSX.Element => {
  const { t } = useTranslation();
  const [resetConfirm, setResetConfirm] = useState(false);
  const api = useApiClient();
  const { currentBrand } = useBrand();
  const { brand } = useParams();
  const navigate = useNavigate();
  const { currencyOptions } = useCurrency();
  const isEditor = useRemarketingEditor();

  const contentItems: ContentItem[] = [
    {
      text: t('pages.remarketingVehiclePage.unassigned.details'),
      hash: 'details',
      sectionElementRef: useRef(null),
    },
    {
      text: t('notes'),
      hash: 'notes',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.remarketingVehiclePage.auction.attachments'),
      hash: 'attachments',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.remarketingVehiclePage.cancel.cancelSectionTitle'),
      hash: 'cancel',
      sectionElementRef: useRef(null),
    },
  ];

  const form = useCreateForm(
    {
      notes: maybe(text),
      countries: array(text),
      shortVin: maybe(text),
      registrationPlate: maybe(text),
      odometer: maybe(
        chain(
          normaliseNumber,
          minNumber({
            min: 0,
          }),
        ),
      ),
      odometerUnit: maybe(enumValue(OdometerUnit)),
      recommendedRetailPrice: maybe(
        chain(
          strToNum,
          minNumber({
            min: 1,
            error: `pages.addRemarketingVehicle.details.form.errors.INVALID_PRICE`,
          }),
        ),
      ),
      currency: maybe(text),
      visibilityDate: maybe(jsonDate),
    },
    async (values) => {
      if (!remarketingVehicle.id || !currentBrand) {
        return;
      }

      const updated = await api.remarketing.adminUpdateRemarketingVehicle({
        remarketingVehicle: {
          id: remarketingVehicle.id,
          adminPrincipalId: remarketingVehicle.adminPrincipalId,
          status: remarketingVehicle.status,
          vehicleMedia: remarketingVehicle.vehicleMedia,
          archived: remarketingVehicle.archived,
          views: remarketingVehicle.views,
          type: RemarketingType.UNASSIGNED,
          notes: values.notes,
          countries: values.countries,
          shortVin: values.shortVin,
          registrationPlate: values.registrationPlate,
          odometer: {
            value: values.odometer,
            units: values.odometerUnit,
          },
          vehicle: {
            ...remarketingVehicle.vehicle,
            rrp: {
              value: values.recommendedRetailPrice,
            },
          },
          currency: values.currency,
          visibilityDate: values.visibilityDate
            ? DateTime.fromJSDate(values.visibilityDate).toMillis()
            : undefined,
        },
        brand: currentBrand,
      });

      onUpdate && onUpdate(updated);

      return updated;
    },
    (values) => {
      const errors: DecoderError[] = [];

      if (values.countries.length === 0) {
        errors.push({
          id: 'INVALID_COUNTRY_REGIONS',
          text: 'INVALID_COUNTRY_REGIONS',
          field: 'regionVisibility',
        });
      }

      if (errors.length > 0) {
        return error(errors);
      }

      return ok(values);
    },
  );

  const [formState, formBind] = form;

  const selectedCurrency = useMemo(
    () => formState.values.currency,
    [formState.values.currency],
  );

  const resetForm = useCallback(() => {
    formBind.reset({
      shortVin: remarketingVehicle.shortVin || '',
      registrationPlate: remarketingVehicle.registrationPlate || '',
      recommendedRetailPrice: String(
        remarketingVehicle.vehicle.rrp?.value || '',
      ),
      odometer: remarketingVehicle.odometer?.value || '',
      odometerUnit: remarketingVehicle.odometer?.units || '',
      notes: remarketingVehicle.notes || '',
      countries: remarketingVehicle.countries || '',
      currency: remarketingVehicle.currency,
      visibilityDate: remarketingVehicle.visibilityDate
        ? DateTime.fromMillis(remarketingVehicle.visibilityDate).toFormat(
            dateTimeFormat,
          )
        : '',
    });
  }, [formBind, remarketingVehicle]);

  const [hasErrors, isDirty] = useMemo(() => {
    const isDirty = Object.keys(formState.dirty).length !== 0;
    return [
      isDirty && Object.values(formState.errors).some((x) => !!x.length),
      isDirty,
    ];
  }, [formState]);

  const toggleResetConfirm = () => {
    setResetConfirm((state) => !state);
  };

  const handleRegionChange = useCallback(
    (regions: RegionCountries) => {
      const selectedCountries = Object.entries(regions).flatMap(
        ([_, countries]) =>
          filterFalsey(
            countries.flatMap((c) => (c.selected ? c.countryCode : undefined)),
          ),
      );

      formBind.setValue('countries', selectedCountries);
    },
    [formBind],
  );

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      direction={{
        md: 'row',
      }}
      spacing={1}
    >
      <Stack flex={1}>
        <Section title={t('pages.remarketingVehiclePage.unassigned.details')}>
          <Stack spacing={4}>
            <Form
              form={form}
              translations="pages.remarketingVehiclePage.auction.form"
            >
              <div
                id={contentItems[0].hash}
                ref={
                  contentItems[0]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <Stack flex={1}>
                  <CellTextInput
                    label="shortVin"
                    name="shortVin"
                    readonly={readonly}
                  />
                  <CellTextInput
                    label="regPlate"
                    name="registrationPlate"
                    readonly={readonly}
                  />
                </Stack>
                <Stack flex={1}>
                  <CellSelectInput
                    label="Currency"
                    name="currency"
                    options={currencyOptions}
                    readonly={readonly}
                  />
                  <CellMoneyInput
                    currency={selectedCurrency}
                    label="recommendedRetailPrice"
                    name="recommendedRetailPrice"
                    readonly={readonly}
                  />
                </Stack>
                <Stack flex={1}>
                  <CellTextInput
                    label="odometer"
                    name="odometer"
                    readonly={readonly}
                  />
                  <CellSelectInput
                    label="odometerUnit"
                    name="odometerUnit"
                    options={odometerUnitOptions}
                    readonly={readonly}
                  />
                  <CellDateTimeInput
                    label="visibilityDate"
                    name="visibilityDate"
                    readonly={readonly}
                  />
                </Stack>
              </div>
              <div
                id={contentItems[1].hash}
                ref={
                  contentItems[1]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <Regions
                  title={t(
                    'pages.addRemarketingVehicle.details.form.labels.regionVisibility',
                  )}
                  vehicle={remarketingVehicle}
                  readonly={readonly}
                  onChange={handleRegionChange}
                />
              </div>
              <div
                id={contentItems[2].hash}
                ref={
                  contentItems[2]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <Comments
                  title={t('notes')}
                  label=""
                  name="notes"
                  readonly={readonly}
                />
              </div>
              <div
                id={contentItems[contentItems.length - 2].hash}
                ref={
                  contentItems[contentItems.length - 2]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <RemarketingDocumentsSection
                  title={t('pages.remarketingVehiclePage.auction.attachments')}
                  brand={remarketingVehicle.vehicle.brand}
                  remarketingVehicleId={remarketingVehicle.id}
                  readonly={
                    !isEditor ||
                    [
                      RemarketingVehicleStatus.TRANSFERRED,
                      RemarketingVehicleStatus.CLOSED,
                      RemarketingVehicleStatus.CANCELLED,
                    ].includes(remarketingVehicle.status)
                  }
                />
              </div>
              {!readonly && (
                <Box m={1} marginLeft="auto">
                  <Stack direction="row" justifyContent="end" spacing={1}>
                    <Button
                      tabIndex={1}
                      disabled={!isDirty || formState.busy}
                      onClick={() => {
                        if (!isDirty) {
                          return;
                        }
                        toggleResetConfirm();
                      }}
                      variant="outlined"
                    >
                      {t('cancel')}
                    </Button>
                    <LightTooltip
                      title={hasErrors ? <FormErrorsSummary /> : ''}
                    >
                      <span>
                        <LoadingButton
                          disabled={hasErrors}
                          endIcon={<Save />}
                          loading={formState.busy}
                          loadingPosition="end"
                          type="submit"
                          variant="contained"
                          tabIndex={1}
                        >
                          {t('save')}
                        </LoadingButton>
                      </span>
                    </LightTooltip>
                  </Stack>
                </Box>
              )}
            </Form>
            {isEditor && (
              <div
                id={contentItems[contentItems.length - 1].hash}
                ref={
                  contentItems[contentItems.length - 1]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <CancelRemarketingSection
                  title={t(
                    'pages.remarketingVehiclePage.cancel.cancelSectionTitle',
                  )}
                  remarketingVehicleId={remarketingVehicle.id}
                  readonly={
                    remarketingVehicle.status ===
                    RemarketingVehicleStatus.CANCELLED
                  }
                />
              </div>
            )}
          </Stack>
        </Section>
      </Stack>
      <div>
        <Section isSticky>
          <Stack>
            <TableOfContents
              items={contentItems}
              offsetPx={-80}
              scrollingElementSelector={MAIN_CONTAINER_ID}
            />
          </Stack>
        </Section>
      </div>
      <ConfirmModal
        onClose={toggleResetConfirm}
        onConfirm={() => {
          resetForm();
          toggleResetConfirm();
          navigate(`/${brand}/remarketing/management`);
        }}
        open={resetConfirm}
        title={t('attention')}
      >
        <Typography variant="body1">{t('discard')}</Typography>
      </ConfirmModal>
    </Stack>
  );
};

export default Unassigned;
