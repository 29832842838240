import { Stack, StackProps, Typography } from '@mui/material';
import { FunctionComponent, PropsWithChildren, ReactElement } from 'react';

type SectionItemProps = PropsWithChildren &
  StackProps & {
    title?: string;
    actions?: ReactElement;
  };

const SectionItem: FunctionComponent<SectionItemProps> = ({
  title,
  actions,
  children,
  ...props
}) => {
  return (
    <Stack my={4} spacing={1} {...props}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={1}
      >
        {!!title && (
          <Typography color="#637281" variant="h6">
            {title}
          </Typography>
        )}
        <div>{actions}</div>
      </Stack>
      <Stack>{children}</Stack>
    </Stack>
  );
};

export default SectionItem;
