import { array, enumValue, object } from '@fmtk/decoders';
import {
  BrandConfig,
  decodeBrandConfig,
} from '../../../apps/util/BrandsConfig.js';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface GetBrandConfigResponse {
  brand: AvailableBrands;
  config: BrandConfig;
}

export const decodeGetBrandConfigResponse = array(
  object<GetBrandConfigResponse>({
    brand: enumValue(AvailableBrands),
    config: decodeBrandConfig,
  }),
);
