import {
  chain,
  Decoder,
  DecoderError,
  object,
  PropDecoders,
  Result,
} from '@fmtk/decoders';
import { DependencyList, useCallback, useMemo, useState } from 'react';
import {
  FormController,
  FormState,
  FormValuesInput,
  FormValuesOutput,
  makeFormController,
} from '../common-ui/index.js';

export interface FormBind<T> {
  handleChange(key: keyof T): (value: unknown) => void;
  reset(value?: FormValuesInput<T>): void;
  setErrors(errors: DecoderError[]): void;
  setValue(key: keyof T, value: unknown, clean?: boolean): void;
  submit(): void;
  validate(): Result<T>;
}

export type FormTypeOf<X> = X extends FormBind<infer T>
  ? T
  : X extends FormState<infer T, any>
  ? T
  : X extends () => Result<infer T>
  ? T
  : never;

export type FormHook<T, SubmitResult> = [
  FormState<T, SubmitResult>,
  FormBind<T>,
];

export type FormDecoders<T> = PropDecoders<T> | (() => PropDecoders<T>);

export function useCreateForm<T, SubmitResult>(
  props: FormDecoders<T>,
  submit: (
    value: T,
    bind: FormController<T>,
  ) => SubmitResult | PromiseLike<SubmitResult>,
  crossField?: Decoder<T, T>,
  deps: DependencyList = [],
): FormHook<T, SubmitResult> {
  const getProps = (): PropDecoders<T> => {
    if (typeof props === 'function') {
      return props();
    }
    return props;
  };

  const initialValue = useMemo(
    () =>
      Object.fromEntries(
        Object.keys(getProps()).map((k) => [k, '']),
      ) as FormValuesOutput<T>,
    deps, // eslint-disable-line react-hooks/exhaustive-deps
  );
  const decoder = useMemo(
    () =>
      crossField ? chain(object(getProps()), crossField) : object(getProps()),
    deps, // eslint-disable-line react-hooks/exhaustive-deps
  );

  const [state, setState] = useState<FormState<T, SubmitResult>>({
    busy: false,
    dirty: {},
    errors: {},
    result: undefined,
    submitError: false,
    values: initialValue,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const submitCallback = useCallback(submit, deps);

  const controller = useMemo(
    () =>
      makeFormController({
        decoder,
        initialValue,
        dispatch: setState,
        submit: submitCallback,
      }),
    [decoder, initialValue, submitCallback],
  );

  const bind = useMemo((): FormBind<T> => {
    const cache = new Map<keyof T, (value: unknown) => void>();

    return {
      handleChange(key: keyof T): (value: unknown) => void {
        let handler = cache.get(key);
        if (!handler) {
          handler = (value) => controller.setValue(key, value);
          cache.set(key, handler);
        }
        return handler;
      },

      reset(values?: FormValuesInput<T>): void {
        controller.reset(values);
      },

      setErrors(errors: DecoderError[]): void {
        controller.setErrors(errors);
      },

      setValue(key: keyof T, value: unknown, clean?: boolean): void {
        controller.setValue(key, value, clean);
      },

      submit(): void {
        controller.submit();
      },

      validate(): Result<T> {
        return controller.validate();
      },
    };
  }, [controller]);

  return useMemo(() => [state, bind], [state, bind]);
}
