import { ExtraFields } from '@fmtk/decoders';
import { ClientError } from './ClientError.js';
import {
  HttpContentType,
  HttpServer,
  HttpServerRequest,
  HttpServerResponse,
} from './HttpServer.js';
import { RequestContext } from './RequestContext.js';
import {
  MethodScope,
  ServiceDefBase,
  ServiceMethodDef,
  ServiceWithContext,
} from './ServiceDef.js';
import { errorResponse } from './errorResponse.js';
import { stringEnum } from './stringEnum.js';

const BindServiceEvents = stringEnum(
  'BadContentTypeError',
  'BadJsonError',
  'ClientError',
  'MethodNotFound',
  'RequestValidationError',
  'ResponseValidationError',
  'ServerError',
  'ServiceRequest',
  'ServiceResponse',
);

const notFoundHandler: HttpServer = async () => errorResponse(404, 'NOT_FOUND');

export function bindServices<Def extends Record<string, ServiceDefBase>>(
  basePath: string,
  def: Def,
  impl: { [K in keyof Def]: ServiceWithContext<Def[K], RequestContext> },
  next = notFoundHandler,
): HttpServer<RequestContext> {
  return async (
    req: HttpServerRequest,
    ctx: RequestContext,
  ): Promise<HttpServerResponse> => {
    if (!basePath.startsWith('/')) {
      basePath = '/' + basePath;
    }
    if (!basePath.endsWith('/')) {
      basePath = basePath + '/';
    }
    let path = req.path;

    if (path === '/') {
      return {
        status: 302,
        headers: { location: 'http://connect.auto' },
      };
    }

    if (!path.startsWith(basePath)) {
      return await next(req);
    }
    path = path.slice(basePath.length);

    const [service, method] = path.split('/');
    if (
      !service ||
      !method ||
      !def[service]?.[method] ||
      !impl[service]?.[method]
    ) {
      return await next(req);
    }

    const methodDef = def[service][method];

    if (!methodDef) {
      ctx.logError(service, BindServiceEvents.MethodNotFound, {
        method,
      });
      return {
        status: 404,
        headers: { 'Content-Type': HttpContentType.Json },
        body: JSON.stringify({
          error: BindServiceEvents.MethodNotFound,
          details: { method },
        }),
      };
    }

    ctx.traceEvent(service, BindServiceEvents.ServiceRequest, req, {
      httpPath: req.path,
      httpMethod: req.method,
      method,
    });

    if (methodDef.scopes !== MethodScope.NoAuth) {
      ctx.requireAnyScope(service, method, ...(methodDef.scopes ?? []));
    }

    const result = await executeRequest(
      impl[service],
      service,
      method,
      methodDef,
      req,
      ctx.withMeta({
        service,
        method,
      }),
    );

    return result;
  };
}

async function executeRequest<Def extends ServiceDefBase>(
  service: ServiceWithContext<Def, RequestContext>,
  serviceName: string,
  methodName: string,
  methodDef: ServiceMethodDef<unknown, unknown>,
  req: HttpServerRequest,
  ctx: RequestContext,
): Promise<HttpServerResponse> {
  const contentType = req.headers.parseContentType()?.type;

  if (req.body && contentType !== HttpContentType.Json) {
    ctx.logError(serviceName, BindServiceEvents.BadContentTypeError, {
      contentType,
    });
    return {
      status: 415,
      headers: { 'Content-Type': HttpContentType.Json },
      body: JSON.stringify({ error: BindServiceEvents.BadContentTypeError }),
    };
  }

  let body: Record<string, unknown> | undefined;

  if (typeof req.body === 'string') {
    try {
      body = JSON.parse(req.body);
    } catch (err) {
      ctx.logError(serviceName, BindServiceEvents.BadJsonError, {
        contentType,
      });
      return {
        status: 400,
        headers: { 'Content-Type': HttpContentType.Json },
        body: JSON.stringify({ error: BindServiceEvents.BadJsonError }),
      };
    }
  } else {
    body = req.body;
  }

  const requestResult = methodDef.request(body, {
    extraFields: ExtraFields.Reject, // be strict about received data
  });
  if (!requestResult.ok) {
    ctx.logError(serviceName, BindServiceEvents.RequestValidationError, {
      errors: requestResult.error,
      value: body,
    });
    return {
      status: 400,
      headers: { 'Content-Type': HttpContentType.Json },
      body: JSON.stringify({
        error: BindServiceEvents.RequestValidationError,
        details: requestResult.error,
      }),
    };
  }

  try {
    const result = await service[methodName](requestResult.value, ctx);

    ctx.traceEvent(serviceName, BindServiceEvents.ServiceResponse, {
      result,
    });

    const resultValidation = methodDef.response(result);

    if (!resultValidation.ok) {
      ctx.logError(serviceName, BindServiceEvents.ResponseValidationError, {
        errors: resultValidation.error,
        value: result,
      });
      return {
        status: 500,
        headers: { 'Content-Type': HttpContentType.Json },
        body: JSON.stringify({
          error: BindServiceEvents.ServerError,
        }),
      };
    }

    ctx.endAuthorization(serviceName, methodName);
    return {
      status: 200,
      headers: { 'Content-Type': HttpContentType.Json },
      body: JSON.stringify(resultValidation.value),
    };
  } catch (err) {
    if (err instanceof ClientError) {
      ctx.logError(serviceName, BindServiceEvents.ClientError, { err });

      return {
        status: err.httpStatus,
        headers: { 'Content-Type': HttpContentType.Json },
        body: JSON.stringify({
          error: err.error,
          details: err.details,
        }),
      };
    } else {
      ctx.logError(serviceName, BindServiceEvents.ServerError, { err });
    }

    return {
      status: 500,
      headers: { 'Content-Type': HttpContentType.Json },
      body: JSON.stringify({
        error: BindServiceEvents.ServerError,
      }),
    };
  }
}
