import { VisibilityOutlined } from '@mui/icons-material';
import { Box, Divider, Grid, Stack, styled, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useInterval } from 'react-use';
import {
  AuctionRemarketingVehicle,
  FixedPriceRemarketingVehicle,
  RemarketingType,
  RemarketingVehicleStatus,
} from '../../../../api/util/RemarketingVehicle.js';
import {
  VehicleMediaStatus,
  VehicleMediaType,
} from '../../../../api/util/VehicleMedia.js';
import { getRelevantMediaFromVehicleMedia } from '../../../../util/vehicleMediaHelper.js';
import { Label } from '../../../common-ui/index.js';
import { useApiClient, useAsyncState, useBrand } from '../../../hooks/index.js';

import { RemarketingVehicleSummary } from '../../../../core/DynamoDbService/commands/GetRemarketingVehicleSummaryCommand.js';
import useCountdown from '../../../hooks/useCountDown.js';
import { formatDateTime } from '../../../util/dates.js';
import { getReservePriceStatus } from '../../../util/getReservePriceStatus.js';
import {
  archivedColour,
  remarketingStatusColours,
  remarketingTypeColours,
  sx,
} from '../../../util/index.js';
import { formatMoney } from '../../../util/money.js';
import {
  FULL_DATE_TIME,
  INTERVAL,
  START_POLING,
} from '../RemarketingVehicle/types.js';
import Details from './Details.js';

type AdminAuctionVehicleCardProps = {
  vehicle: AuctionRemarketingVehicle | FixedPriceRemarketingVehicle;
  onImageClick?: () => void;
};

const preActiveStates = [
  RemarketingVehicleStatus.PIPELINE,
  RemarketingVehicleStatus.VISIBLE,
];

const postActiveStates = [
  RemarketingVehicleStatus.CANCELLED,
  RemarketingVehicleStatus.CLOSED,
  RemarketingVehicleStatus.TRANSFERRED,
];

const styles = sx({
  card: {
    padding: {
      xs: 0,
      md: 3,
    },
    backgroundColor: {
      xs: 'background.paper',
      md: 'background.default',
    },
    borderRadius: '4px',
    gap: 2,
  },
  body: {
    bgcolor: 'background.default',
    p: {
      xs: 2,
      md: 0,
    },
  },
  priceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
    justifyContent: 'flex-end',
    display: {
      xs: 'flex',
      md: 'none',
    },
  },
  priceItem: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
    justifyContent: {
      xs: 'flex-start',
      sm: 'flex-end',
    },
  },
  priceAlign: {
    textAlign: {
      xs: 'left',
      lg: 'right',
    },
  },
  dropDownButton: {
    bgcolor: 'background.paper',
  },
  viewsContainer: {
    position: 'absolute',
    margin: '5px; 6px;',
    display: 'flex',
    gap: 1,
    zIndex: 1,
  },
  printButton: {
    borderColor: 'grey.600',
    border: '2px solid #919EAB',
    bgcolor: '#FFF',
    color: 'grey.600',
  },
  boxContainer: {
    bgcolor: 'background.paper',
    border: 2,
    borderColor: '#BDC7D3',
    borderRadius: 0.5,
    flex: 1,
    maxWidth: 800,
    gap: 0.5,
    mt: {
      xs: 0,
      md: 1,
    },
    p: 1,
  },
  divider: {
    height: 2,
  },
  verticalDivider: {
    width: 2,
  },
  labels: {
    padding: '2px 6px',
  },
});

const Img = styled('img')<{ archived?: boolean }>(({ archived, theme }) => ({
  width: '100%',
  aspectRatio: '4/3',
  borderRadius: '4px',
  objectFit: 'contain',
  [theme.breakpoints.up('md')]: {
    height: 150,
    width: 'auto',
  },
  filter: archived ? 'grayscale(1)' : 'inherit',
}));

export default function AdminAuctionVehicleCard({
  vehicle,
  onImageClick,
}: AdminAuctionVehicleCardProps) {
  const { brandConfig } = useBrand();
  const { t, i18n } = useTranslation();
  const api = useApiClient();
  const [summary, setSummary] = useAsyncState<RemarketingVehicleSummary>();
  const [remarketingVehicle, setRemarketingVehicle] = useState(vehicle);
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const closingDate = remarketingVehicle.closingDate
    ? DateTime.fromMillis(remarketingVehicle.closingDate, {
        locale: i18n.language,
      })
    : undefined;
  const { timeRemaining, duration, inThePast, timeLeft } =
    useCountdown(closingDate, {
      stopOnZero: true,
      toRelativeOptions: {
        locale: i18n.language,
        unit: ['years', 'months', 'days', 'hours', 'minutes'],
      },
    }) || {};

  const {
    imgSrc,
    detailsItem,
    vehicleTitle,
    price,
    reservePrice,
    eventMessage,
    reservePriceMessage,
    showEndDate,
    showStartDate,
    archived,
    currentOffer,
  } = useMemo(() => {
    const vehicleTitle = `${
      remarketingVehicle.vehicle.modelYear ||
      remarketingVehicle.vehicle.registrationYear ||
      ''
    } ${remarketingVehicle.vehicle.model.name}`;

    const archived = remarketingVehicle.archived;

    const media = getRelevantMediaFromVehicleMedia({
      vehicleMedia: remarketingVehicle?.vehicleMedia || [],
      options: {
        mediaType: [VehicleMediaType.Image, VehicleMediaType.Legacy],
        mediaStatus: [VehicleMediaStatus.Approved],
        transformation: 't_vehicle_thumbnail',
      },
    });

    const imgSrc = media?.mediaUrl || brandConfig?.noVehicleImage;

    const detailsItem = [
      {
        label: t('vin'),
        value: remarketingVehicle.vehicle.vin.toUpperCase(),
      },
      {
        label: t('endDate'),
        value: remarketingVehicle.closingDate
          ? formatDateTime(
              DateTime.fromMillis(remarketingVehicle.closingDate).toJSDate(),
            )
          : undefined,
      },
    ];

    const priceValue =
      remarketingVehicle.type === RemarketingType.FIXED_PRICE
        ? remarketingVehicle.price
        : remarketingVehicle.currentBid?.currentPrice ||
          remarketingVehicle.startPrice;

    const price = formatMoney(priceValue || 0);

    const reservePrice =
      'reservePrice' in remarketingVehicle
        ? formatMoney(remarketingVehicle.reservePrice)
        : 0;

    const bidders = remarketingVehicle.bidders?.length;

    const reservePriceMessage =
      'reservePrice' in remarketingVehicle
        ? t(
            `reservePriceStatus.${getReservePriceStatus(
              remarketingVehicle.startPrice,
              remarketingVehicle.reservePrice,
              remarketingVehicle.currentBid?.currentPrice || 0,
            )}`,
          )
        : '';

    const currentOffer =
      'currentOffer' in remarketingVehicle &&
      remarketingVehicle.currentOffer &&
      postActiveStates.includes(remarketingVehicle.status)
        ? t('terms.currentOffer', {
            dealer: remarketingVehicle.currentOffer.dealerName,
          })
        : undefined;

    const eventMessage =
      remarketingVehicle.type === RemarketingType.FIXED_PRICE
        ? t(bidders ? 'terms.offerersCount' : 'terms.noOffers', {
            value: bidders,
          })
        : bidders
        ? t('terms.biddersCount', { value: bidders })
        : undefined;

    const showEndDate =
      remarketingVehicle.status !== RemarketingVehicleStatus.ACTIVE ||
      inThePast;

    const showStartDate = preActiveStates.includes(remarketingVehicle.status);

    return {
      imgSrc,
      detailsItem,
      vehicleTitle,
      price,
      reservePrice,
      eventMessage,
      reservePriceMessage,
      showEndDate,
      showStartDate,
      archived,
      currentOffer,
    };
  }, [remarketingVehicle, brandConfig?.noVehicleImage, t, inThePast]);

  useInterval(
    () => {
      setSummary(async () => {
        const response = await api.remarketing.getAuctionVehicleSummary({
          brand: vehicle.vehicle.brand,
          id: vehicle.id,
        });

        return response;
      });
    },
    timeRemaining && timeRemaining <= START_POLING ? INTERVAL : null,
  );

  useEffect(() => {
    setRemarketingVehicle((state) => {
      return {
        ...state,
        ...vehicle,
        ...summary.value,
      } as AuctionRemarketingVehicle;
    });
  }, [vehicle, summary.value]);

  useEffect(() => {
    if (location.state?.remarketingId === vehicle.id) {
      containerRef.current?.scrollIntoView({ block: 'center' });
    }
  }, [location.state?.remarketingId, vehicle.id]);

  return (
    <Grid container ref={containerRef} sx={styles.card}>
      <Grid container item md="auto" position="relative">
        <Box sx={styles.viewsContainer}>
          <Label bgcolor={archived ? archivedColour.background : '#172B4D'}>
            <Stack direction="row" spacing={1}>
              <VisibilityOutlined fontSize="medium" htmlColor="#FFF" />
              <Typography
                color="#FFF"
                fontWeight="fontWeightMedium"
                textTransform="capitalize"
                variant="body2"
              >
                {t('terms.views', {
                  value: remarketingVehicle.views || 0,
                })}
              </Typography>
            </Stack>
          </Label>
        </Box>
        <Img
          alt="vehicle img"
          archived={archived}
          onClick={onImageClick}
          onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.src = brandConfig?.noVehicleImage || '';
          }}
          src={imgSrc}
          sx={{
            cursor: remarketingVehicle.vehicleMedia?.length
              ? 'pointer'
              : 'default',
          }}
        />
      </Grid>
      <Grid
        container
        flexDirection="column"
        item
        justifyContent="space-between"
        xs
      >
        <Grid container direction="column" item rowGap={{ xs: 2, md: 0 }}>
          <Grid
            columnGap={3}
            container
            flexDirection={{
              xs: 'column',
              md: 'row',
            }}
            item
            rowGap={1}
          >
            <Grid item>
              <Typography
                color={archived ? 'inherit' : 'primary'}
                component={Link}
                sx={{
                  textDecoration: 'underline',
                }}
                to={`details/${remarketingVehicle.id}`}
                variant="h4"
              >
                {vehicleTitle}
              </Typography>
            </Grid>
            <Grid alignContent="center" columnGap={1} container item xs="auto">
              <Label
                bgcolor={
                  archived
                    ? archivedColour.background
                    : remarketingStatusColours[remarketingVehicle.status]
                        .background
                }
                sx={styles.labels}
              >
                <Typography
                  color={
                    archived
                      ? archivedColour.color
                      : remarketingStatusColours[remarketingVehicle.status]
                          .color
                  }
                  fontWeight="fontWeightBold"
                  textTransform="uppercase"
                  variant="caption"
                >
                  {t(`remarketingVehicleStatus.${remarketingVehicle.status}`)}
                </Typography>
              </Label>
              <Label
                bgcolor={
                  archived
                    ? archivedColour.background
                    : remarketingTypeColours[remarketingVehicle.type].background
                }
                sx={styles.labels}
              >
                <Typography
                  color={
                    archived
                      ? archivedColour.color
                      : remarketingTypeColours[remarketingVehicle.type].color
                  }
                  fontWeight="fontWeightBold"
                  textTransform="uppercase"
                  variant="caption"
                >
                  {t(`remarketingTypes.${remarketingVehicle.type}`)}
                </Typography>
              </Label>
            </Grid>
          </Grid>
          <Grid sx={styles.priceContainer}>
            <Typography
              color="grey.600"
              fontWeight="fontWeightMedium"
              gutterBottom
              m={0}
              variant="body2"
            >
              {t('pages.remarketingAdmin.leadingBid')}
            </Typography>
            <Typography variant="h5">
              {remarketingVehicle.currency} {price}
            </Typography>
          </Grid>
          <Grid container flexDirection="column" item sx={styles.body}>
            <Details items={detailsItem} />
          </Grid>
        </Grid>
        <Grid container item sx={styles.body} xs>
          <Stack
            divider={<Divider color="#F7F8F9" sx={styles.divider} />}
            justifyContent="space-between"
            sx={styles.boxContainer}
          >
            {!preActiveStates.includes(remarketingVehicle.status) && (
              <Stack>
                <Stack
                  direction="row"
                  divider={
                    <Divider
                      color="#BDC7D3"
                      orientation="vertical"
                      sx={styles.verticalDivider}
                    />
                  }
                  flex={1}
                  spacing={1}
                >
                  {eventMessage && (
                    <Typography color="grey.700" variant="subtitle2">
                      {eventMessage}
                    </Typography>
                  )}
                  {reservePriceMessage && (
                    <Typography color="grey.700" variant="subtitle2">
                      {reservePriceMessage}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            )}
            {currentOffer && (
              <Typography color="grey.700" variant="subtitle2">
                {currentOffer}
              </Typography>
            )}
            {showStartDate && (
              <div>
                <Stack direction="row" spacing={1}>
                  <Typography
                    color="grey.600"
                    fontWeight="fontWeightMedium"
                    variant="body2"
                  >
                    {t('start')}
                  </Typography>
                  <Typography
                    color="grey.800"
                    fontWeight="fontWeightMedium"
                    variant="body2"
                  >
                    {formatDateTime(remarketingVehicle.startingDate)}
                  </Typography>
                </Stack>
              </div>
            )}
            {!showEndDate && !!timeLeft && (
              <Stack
                direction={{
                  xs: 'column',
                  md: 'row',
                }}
                justifyContent="space-between"
              >
                <div>
                  <Stack direction="row" spacing={1}>
                    <Typography
                      color="grey.600"
                      fontWeight="fontWeightMedium"
                      variant="body2"
                    >
                      {t('timeLeft')}
                    </Typography>

                    <Typography
                      color="error.dark"
                      fontWeight="fontWeightMedium"
                      variant="body2"
                    >
                      {timeLeft}
                    </Typography>
                  </Stack>
                </div>
                <Stack></Stack>
              </Stack>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Grid container item justifyContent="flex-end" lg={3} md={4} xs={12}>
        <Grid
          container
          flexDirection="column"
          item
          justifyContent="space-between"
        >
          <Grid
            container
            direction="column"
            display={{
              xs: 'none',
              md: 'flex',
            }}
            item
          >
            <Stack sx={styles.priceItem}>
              <Typography
                color="grey.600"
                fontWeight="fontWeightMedium"
                gutterBottom
                m={0}
                sx={styles.priceAlign}
                variant="body2"
              >
                <>
                  {remarketingVehicle.type === RemarketingType.AUCTION
                    ? t(
                        remarketingVehicle.currentBid
                          ? 'pages.remarketingAdmin.leadingBid'
                          : 'pages.remarketingAdmin.startPrice',
                      )
                    : t('price')}
                </>
              </Typography>
              <Typography sx={styles.priceAlign} variant="h5">
                {remarketingVehicle.currency} {price}
              </Typography>
            </Stack>
            <Stack sx={styles.priceItem}>
              {!showEndDate && !!duration && (
                <Typography
                  color="error.dark"
                  sx={styles.priceAlign}
                  variant="subtitle2"
                >
                  {duration.toFormat(FULL_DATE_TIME)}
                </Typography>
              )}
            </Stack>
            {!!reservePrice && (
              <Stack sx={styles.priceItem}>
                <Typography
                  color="grey.600"
                  fontWeight="fontWeightMedium"
                  gutterBottom
                  m={0}
                  sx={styles.priceAlign}
                  variant="body2"
                >
                  {t('pages.remarketingAdmin.reservePrice')}
                </Typography>
                <Typography
                  color="grey.700"
                  sx={styles.priceAlign}
                  variant="subtitle2"
                >
                  {remarketingVehicle.currency} {reservePrice}
                </Typography>
              </Stack>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
