import {
  ExtraFields,
  array,
  enumValue,
  maybe,
  number,
  object,
  optional,
  record,
  string,
} from '@fmtk/decoders';
import { AvailableBrands, isBrandDisplayName } from './AvailableBrands.js';
import { DealerTypes } from './DealerTypes.js';
import { GeoLocation, decodeGeoLocation } from './GeoLocation.js';
import { HandDrive } from './HandDrive.js';

export interface DealerGroup {
  id: number;
  name: string;
}

export const decodeDealerGroup = object<DealerGroup>({
  name: string,
  id: number,
});

export enum DealerFeature {
  BypassImageQC = 666,
  BackgroundReplacement = 669,
  ImageReordering = 670,
}

export const decodeDealerFeature = enumValue(DealerFeature);

export interface DealerRegion {
  id: number;
  name: string;
  subregion?: string;
  subregionId?: number;
}

export const decodeDealerRegion = object<DealerRegion>({
  name: string,
  id: number,
  subregion: maybe(string),
  subregionId: maybe(number),
});

export const getRegionNameString = (region?: DealerRegion): string => {
  if (!region) {
    return '';
  }

  if (region.subregion) {
    return `${region.name} - ${region.subregion}`;
  }
  return region.name;
};

export interface WorkingHours {
  monday?: string;
  tuesday?: string;
  wednesday?: string;
  thursday?: string;
  friday?: string;
  saturday?: string;
  sunday?: string;
  mondayToFriday?: string;
}

export const decodeWorkingHours = object<WorkingHours>({
  monday: maybe(string),
  tuesday: maybe(string),
  wednesday: maybe(string),
  thursday: maybe(string),
  friday: maybe(string),
  saturday: maybe(string),
  sunday: maybe(string),
  mondayToFriday: maybe(string),
});

export type DealerLookup = Record<string, string | undefined>;

export const decodeDealerLookup = record(string, maybe(string));

// By convention, Client IDs are stored in dealerLookup with a key of the brand name
export const dealerLookupToClientId = (lookup?: DealerLookup): string => {
  for (const key in lookup) {
    if (isBrandDisplayName(key) && lookup[key]) {
      return lookup[key] ?? '';
    }
  }
  return '';
};

export const dealerLookupToString = (
  lookup?: DealerLookup,
  options?: { omitClientId: boolean },
): string => {
  if (!lookup) {
    return '';
  }

  return Object.keys(lookup)
    .filter((key) => (options?.omitClientId ? !isBrandDisplayName(key) : true))
    .sort()
    .map((key) => `${key}: ${lookup[key]}`)
    .join(', ');
};

export type Dealer = {
  id: number;
  brand: AvailableBrands;
  address?: string;
  countryCode?: string;
  countryName?: string;
  currency?: string;
  dealerGroup?: DealerGroup;
  dealerLookup?: DealerLookup;
  email?: string[];
  emailPhotos?: string[];
  features?: DealerFeature[];
  language?: string[];
  name: string;
  odometerUnit?: string;
  workingHours?: WorkingHours;
  phone?: string;
  point?: GeoLocation;
  region: DealerRegion;
  type?: DealerTypes;
  website?: string;
  handDrive?: HandDrive;
};

export const decodeDealerProps = {
  id: number,
  brand: enumValue(AvailableBrands),
  address: maybe(string),
  countryCode: maybe(string),
  countryName: maybe(string),
  currency: maybe(string),
  dealerGroup: maybe(decodeDealerGroup),
  dealerLookup: maybe(decodeDealerLookup),
  email: maybe(array(string)),
  emailPhotos: maybe(array(string)),
  features: maybe(array(number)),
  language: maybe(array(string)),
  name: string,
  odometerUnit: maybe(string),
  workingHours: maybe(decodeWorkingHours),
  point: maybe(decodeGeoLocation),
  phone: maybe(string),
  region: decodeDealerRegion,
  type: maybe(enumValue(DealerTypes)),
  website: maybe(string),
  handDrive: maybe(enumValue(HandDrive)),
};

export const decodeDealer = object<Dealer>(decodeDealerProps);

export type DealerIdPartial = Partial<Dealer> & Pick<Dealer, 'id'>;

export const decodeDealerIdPartial = object<DealerIdPartial>({
  ...decodeDealerProps,
  brand: maybe(enumValue(AvailableBrands)),
  name: maybe(string),
  region: maybe(decodeDealerRegion),
});

export type DealerSummary = Pick<
  Dealer,
  | 'id'
  | 'name'
  | 'countryCode'
  | 'countryName'
  | 'region'
  | 'email'
  | 'brand'
  | 'point'
>;

export const decodeDealerSummary = object<DealerSummary>(
  {
    id: number,
    name: string,
    countryCode: optional(string),
    countryName: optional(string),
    region: decodeDealerRegion,
    email: optional(array(string)),
    brand: enumValue(AvailableBrands),
    point: optional(decodeGeoLocation),
  },
  {
    extraFields: ExtraFields.Ignore,
  },
);
