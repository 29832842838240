import { chain, number, strToNum } from '@fmtk/decoders';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useInterval } from 'react-use';
import { SubmitOfferErrors } from '../../../../../../api/RemarketingService/common.js';
import { AvailableBrands } from '../../../../../../api/util/AvailableBrands.js';
import { HttpServiceTransportError } from '../../../../../../api/util/HttpServiceTransportError.js';
import {
  BidActionResult,
  BidRemarketingAction,
} from '../../../../../../api/util/RemarketingAction.js';
import { UserAuctionRemarketingVehicle } from '../../../../../../api/util/UserRemarketingVehicle.js';
import { minNumber } from '../../../../../../util/decoders/minNumber.js';
import {
  Form,
  FormTextField,
  useMessageStore,
} from '../../../../../common-ui/index.js';
import { useApiClient } from '../../../../../hooks/useApiClient.js';
import { useAsyncState } from '../../../../../hooks/useAsyncState.js';
import { useCreateForm } from '../../../../../hooks/useCreateForm.js';
import { useDownloadDocument } from '../../../../../hooks/useDownloadDocument.js';
import { getCurrencySymbol } from '../../../../../util/numbers.js';
import { sx } from '../../../../../util/sx.js';

const styles = sx({
  fieldContainer: {
    maxWidth: 300,
    flex: 'auto',
  },
});

export const REFRESH = 1000;
export const MAX_ATTEMPTS = 10;

interface BidModalProps {
  open: boolean;
  remarketingVehicle: UserAuctionRemarketingVehicle;
  dealerId: number;
  brand: AvailableBrands;
  onClose: () => void;
  remarketingBidStep?: number;
  onSubmit?: (action: BidRemarketingAction) => void;
  fullScreen?: boolean;
  isLoading: boolean;
}

const BidModal: FunctionComponent<BidModalProps> = ({
  open,
  remarketingVehicle,
  dealerId,
  brand,
  onClose,
  onSubmit,
  fullScreen,
  remarketingBidStep,
  isLoading,
}) => {
  const { t } = useTranslation();
  const api = useApiClient();
  const { showMessage } = useMessageStore();
  const [refreshState, setRefreshState] = useAsyncState<BidRemarketingAction>();
  const [newBid, setNewBid] = useState<BidRemarketingAction>();
  const [attempts, setAttempts] = useState(1);
  const [, downloadDocument] = useDownloadDocument();
  const [disabledSubmit, setDisableSubmit] = useState(false);
  const [minBid, setMinBid] = useState(
    remarketingVehicle.leadingBid
      ? remarketingVehicle.leadingBid + (remarketingBidStep || 1000)
      : remarketingVehicle.startPrice,
  );

  const form = useCreateForm(
    {
      bid: chain(strToNum, minNumber({ min: minBid })),
      remarketingBidStep: number,
    },
    async (values) => {
      try {
        const response = await api.remarketing.userSubmitAuctionBid({
          bid: values.bid,
          brand,
          dealerId,
          remarketingVehicleId: remarketingVehicle.id,
        });

        setAttempts((state) => state + 1);
        if (response) {
          setNewBid(response as BidRemarketingAction);
        }
      } catch (error) {
        if (HttpServiceTransportError.is(error, SubmitOfferErrors.NOT_ACTIVE)) {
          showMessage({
            severity: 'error',
            text: t(
              `pages.remarketingVehiclePage.fixedPrice.errors.${error.code}`,
            ),
            dismissible: true,
          });
          setDisableSubmit(true);
        } else {
          showMessage({
            severity: 'error',
            text: t(`errorOccurredMessage`),
            dismissible: true,
          });
        }
      }
    },
    undefined,
    [remarketingVehicle, brand, dealerId, minBid],
  );

  const delay =
    !refreshState.loading && !newBid?.result && attempts <= MAX_ATTEMPTS
      ? REFRESH * attempts * 0.8
      : null;

  useInterval(() => {
    if (!newBid) {
      return;
    }
    setRefreshState(async () => {
      const result = await api.remarketing.userGetRemarketingAction({
        ...newBid,
      });

      setNewBid(result as BidRemarketingAction);

      return result as BidRemarketingAction;
    });
  }, delay);

  const [formState, formBind] = form;

  const loading =
    isLoading ||
    refreshState.loading ||
    formState.busy ||
    (!!newBid && !newBid.result);

  useEffect(() => {
    if (newBid?.result) {
      showMessage({
        text: t(
          `pages.remarketingVehiclePage.auction.bidResult.${newBid.result}`,
        ),
        severity: newBid.result === BidActionResult.LOST ? 'info' : 'success',
        dismissible: true,
        duration: 8000,
      });
      onSubmit && onSubmit(newBid);
      onClose();
    }
  }, [newBid, onClose, onSubmit, showMessage, t]);

  useEffect(() => {
    const currentBid =
      remarketingVehicle.currentBid?.maxBid ?? remarketingVehicle.leadingBid;
    const suggestedBid = currentBid
      ? currentBid + (remarketingBidStep || 1000)
      : remarketingVehicle.startPrice;

    formBind.reset({
      bid: suggestedBid.toString(),
      remarketingBidStep: remarketingBidStep || 1000,
    });
    setMinBid(suggestedBid);
  }, [
    formBind,
    remarketingBidStep,
    remarketingVehicle.leadingBid,
    remarketingVehicle.startPrice,
    remarketingVehicle.currentBid,
  ]);

  useEffect(() => {
    if (formState.submitError || refreshState.error) {
      showMessage({
        severity: 'error',
        text: t('errorOccurredMessage'),
        dismissible: true,
      });
    }
  }, [refreshState.error, formState, showMessage, t]);

  return (
    <Dialog
      disableEnforceFocus
      disableEscapeKeyDown
      fullScreen={fullScreen}
      maxWidth="md"
      onClose={onClose}
      open={open}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          pr: 1,
          borderBottom: (theme) => `1px solid ${theme.palette.grey[500]}`,
        }}
      >
        <DialogTitle>
          {t('pages.remarketingVehiclePage.auction.form.actions.submitBid')}
        </DialogTitle>
        <IconButton
          aria-label="close"
          disabled={loading}
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </Stack>
      <DialogContent>
        <Form
          form={form}
          translations="pages.remarketingVehiclePage.auction.form"
        >
          <Stack spacing={3}>
            {remarketingVehicle.termsAndConditions && (
              <Typography variant="body2">
                <Trans
                  i18nKey="pages.remarketingVehiclePage.auction.form.userTermsAndConditions"
                  t={t}
                >
                  x
                  <Link
                    color="primary"
                    onClick={() => {
                      if (!remarketingVehicle.termsAndConditions) {
                        return;
                      }
                      downloadDocument(remarketingVehicle.termsAndConditions);
                    }}
                    underline="hover"
                    variant="button"
                  ></Link>
                </Trans>
              </Typography>
            )}
            <Stack sx={styles.fieldContainer}>
              <FormTextField
                disabled={loading}
                helperText={t('3minRule')}
                label={t(
                  `pages.remarketingVehiclePage.auction.form.labels.bid`,
                )}
                min={minBid}
                name="bid"
                startAdornment={getCurrencySymbol(remarketingVehicle.currency)}
              />
              <Typography color="#637381" variant="subtitle2">
                {t('terms.bidStep', {
                  value: 1000,
                  currency: remarketingVehicle.currency,
                })}
              </Typography>
            </Stack>
            <Stack
              direction={{
                xs: 'column',
                sm: 'row-reverse',
              }}
              spacing={2}
            >
              <LoadingButton
                disabled={disabledSubmit}
                fullWidth
                loading={loading}
                type="submit"
                variant="contained"
              >
                {t('submit')}
              </LoadingButton>
              <Button
                color="inherit"
                disabled={loading}
                fullWidth
                onClick={onClose}
                variant="outlined"
              >
                {t('cancel')}
              </Button>
            </Stack>
          </Stack>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default BidModal;
